import React, { useEffect, useReducer, useState } from "react";
import { Link } from "react-router-dom";

























import TopbarHeader from "./TopbarHeader";


























function Header() {
  const [search, setSearch] = useState();
  const [sidebar, setSidebar] = useState();
  // Sticky Menu Area
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  /* Method that will fix header after a specific scrollable */
  const isSticky = (e) => {
    const header = document.querySelector(".header-area");
    const scrollTop = window.scrollY;
    scrollTop >= 20
    ? header.classList.add("sticky")
    : header.classList
    ? header.classList.remove("sticky")
    : header.classList.add("sticky");
  };

  /*---------menu button event----------*/
  const handleSidbarMenu = () => {
    if (sidebar === false || sidebar === 0) {
      setSidebar(1);
    } else {
      setSidebar(false);
    }
  };

  /*---------add event scroll top----------*/
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  const searchFullScreen = () => {
    if (search === false || search === 0) {
      setSearch(1);
      console.log(search);
    } else {
      setSearch(false);
    }
  };

  /*---- -----Using reducer mange the active or inactive menu----------*/
  const initialState = { activeMenu: "" };
  const [state, dispatch] = useReducer(reducer, initialState);

  function reducer(state, action) {
    setSidebar(false);
    switch (action.type) {
      case "homeOne":
        return { activeMenu: "homeOne" };
      case "pages":
        return { activeMenu: "pages" };
      case "news":
        return { activeMenu: "news" };
      case "brows":
        return { activeMenu: "brows" };
      case "itwork":
        return { activeMenu: "itwork" };
      case "about":
        return { activeMenu: "about" };
      case "contact":
        return { activeMenu: "contact" };
      default:
        return { activeMenu: "" };
    }
  }

  return (
    <>
      <TopbarHeader />
      <div className={search === 1 ? "mobile-search slide" : "mobile-search"}>
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-md-11">
              <label>What are you lookking for?</label>
              <input
                type="text"
                placeholder="Search Products, Category, Brand"
              />
            </div>
            <div className="col-1 d-flex justify-content-end align-items-center">
              <div className="search-cross-btn " onClick={searchFullScreen}>
                {/* <i class="bi bi-search me-4"></i> */}
                <i className="bi bi-x" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <header className="header-area style-1">
        <div className="header-logo">
          <Link to={`${process.env.PUBLIC_URL}/`} onClick={scrollTop}>
            <img
             width={70}
             height={50}
            
              alt="logo"
              src={process.env.PUBLIC_URL + "/images/bg/shukisha-logo-no-text.svg"}
            />
          </Link>
        </div>
        <div className={sidebar === 1 ? "main-menu show-menu" : "main-menu"}>
          <div className="mobile-logo-area d-lg-none d-flex justify-content-between align-items-center">
            <div className="mobile-logo-wrap ">
              <Link to={"/"}>
                <img
                  alt="logo"
                  height={70}
                  src={process.env.PUBLIC_URL + "/images/bg/shukisha-logo-no-text.svg"}
                />
              </Link>
            </div>
            <div className="menu-close-btn" onClick={handleSidbarMenu}>
              <i className="bi bi-x-lg" />
            </div>
          </div>
          <ul className="menu-list">
           
            <li onClick={() => dispatch({ type: "homeTwo" })}>
              <Link
                to={`${process.env.PUBLIC_URL}/`}
                onClick={scrollTop}
                className={`${state.activeMenu === "homeTwo" ? "active" : ""} `}
              >
                Home
              </Link>
            </li>

            

            <li onClick={() => dispatch({ type: "dashboard" })}>
              <Link
                to={`${process.env.PUBLIC_URL}/dashboard`}
                onClick={scrollTop}
                className={`${state.activeMenu === "dashboard" ? "active" : ""} `}
              >
                My Account
              </Link>
            </li>

            <li onClick={() => dispatch({ type: "demo" })} >
              <Link
                to={`${process.env.PUBLIC_URL}/demo`}
                onClick={scrollTop}
                className={`${state.activeMenu === "demo" ? "active" : ""} `}
              >
               <span className="demo-link">Demo Account</span> 
              </Link>
            </li>

            

            <li onClick={() => dispatch({ type: "itwork" })}>
              <Link
                to={`${process.env.PUBLIC_URL}/how-works`}
                onClick={scrollTop}
                className={`${state.activeMenu === "itwork" ? "active" : ""} `}
              >
                How It Works
              </Link>
            </li>

            <li onClick={() => dispatch({ type: "towin" })}>
              <Link
                to={`${process.env.PUBLIC_URL}/how-to-win`}
                onClick={scrollTop}
                className={`${state.activeMenu === "towin" ? "active" : ""} `}
              >
                How To Win
              </Link>
            </li>

            <li onClick={() => dispatch({ type: "makemoney" })}>
              <Link
                to={`${process.env.PUBLIC_URL}/make-money`}
                onClick={scrollTop}
                className={`${state.activeMenu === "makemoney" ? "active" : ""} `}
              >
                Make Money
              </Link>
            </li>


            <li onClick={() => dispatch({ type: "terms" })}>
              <Link
                to={`${process.env.PUBLIC_URL}/terms`}
                onClick={scrollTop}
                className={`${state.activeMenu === "terms" ? "active" : ""} `}
              >
                Terms
              </Link>
            </li>

            <li onClick={() => dispatch({ type: "about" })}>
              <Link
                to={`${process.env.PUBLIC_URL}/about`}
                onClick={scrollTop}
                className={`${state.activeMenu === "about" ? "active" : ""} `}
              >
                About Us
              </Link>
            </li>
            
            
            
            <li onClick={() => dispatch({ type: "contact" })}>
              <Link
                to={`${process.env.PUBLIC_URL}/contact`}
                onClick={scrollTop}
                className={`${state.activeMenu === "contact" ? "active" : ""} `}
              >
                Contact
              </Link>
              
            </li>


            <li onClick={() => dispatch({ type: "signup" })}>
              <Link
                to={`${process.env.PUBLIC_URL}/signup`}
                onClick={scrollTop}
                className={`${state.activeMenu === "signup" ? "active" : ""} `}
              >
                Signup
              </Link>
              
            </li>


          </ul>
          
        </div>
        
        <div className="nav-right d-flex align-items-center">
          <div className="hotline d-xxl-flex d-none">
            <div className="hotline-icon">
              <img
                alt="images"
                src={process.env.PUBLIC_URL + "/images/icons/header-phone.svg"}
              />
            </div>
            <div className="hotline-info">
              <span>Click To Call</span>
              <h6>
                <a href="tel:+254205002033">+254205002033</a>
              </h6>
            </div>
          </div>
         
          <div className="eg-btn btn--primary header-btn">
            <Link to={`${process.env.PUBLIC_URL}/dashboard`} onClick={scrollTop}>
              My Account
            </Link>
          </div>
          <div
            className="mobile-menu-btn d-lg-none d-block"
            onClick={handleSidbarMenu}
          >
            <i className="bx bx-menu" />
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
