import React from 'react'
import { Link } from 'react-router-dom'


function FaqWrap() {
  return (
    <>
      <div className="faq-section pt-120 pb-120">
        <div className="container">
          <div className="row d-flex justify-content-center gy-5">
           
            <div className="col-lg-8 col-md-12 text-center order-lg-2 order-1">
              <h2 className="section-title3">General FAQ’s</h2>
              <div className="faq-wrap wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".2s">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        How do I sign up for an account?
                      </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>
                          To sign up for an account, click on the “My Account” button on the top right corner of the page. You will be redirected to the sign up page where you will be required to fill in your phone number, screen name and agree to the terms and conditions. Once you have filled in the required information, click on the “Create Account” button. You will receive your login information via SMS. You will be redirected to the login page where you will be required to enter your phone number and password to log in to your account.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        Is there a cost to bidding?
                      </button>
                    </h2>
                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>
                           Yes, each bid costs 50 KES, which is deducted from your account balance. You can check your account balance by clicking on the “My Account” button on the top right corner of the page. You will be redirected to the account page where you will be able to see your account balance.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        How long until my payment reflects in my account?
                      </button>
                    </h2>
                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>
                          Your payment will reflect in your account within 5 minutes of making the payment but it is usually near-instant. If you experience a delay, please contact our customer support team immediately.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFour">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                        What if I forget my password?      
                      </button>
                    </h2>
                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>Not to worry, you can reset your password by clicking on the “Forgot Password” button on the login page.
                           You will be redirected to the password reset page where you will be required to enter your phone number. 
                           You will receive a password reset code via SMS. Enter the code in the password reset code field and click on the “Reset Password” button. 
                           You will receive a new password via SMS which you can then use to log into your account. 
                           You will be redirected to the login page where you will be required to enter your phone number and new password to log in to your account.</p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFive">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                        Will you deliver my purchases?
                      </button>
                    </h2>
                    <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>
                          Yes, we will deliver your purchases to your doorstep. You will be required to pay a delivery fee of 100 KES for each delivery. You can check your delivery status by clicking on the “My Account” button on the top right corner of the page. You will be redirected to the account page where you will be able to see your delivery status.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSix">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                        What is the quality of your products?
                      </button>
                    </h2>
                    <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>
                          We only sell high quality products. We have a team of quality assurance experts who ensure that all products are of high quality before they are sold on our platform.
                        </p>
                      </div>
                    </div>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FaqWrap