import React, { useEffect, useRef, useState } from "react";

function parseDate(str) {
  const [dateparts, timeparts] = str.split(" ");
  const [year, month, day] = dateparts.split("-");
  const [hours = 0, minutes = 0, seconds = 0] = timeparts?.split(":") ?? [];
  // Treats the string as UTC, but you can remove the `Date.UTC` part and use
  // `new Date` directly to treat the string as local time
  return new Date(Date.UTC(+year, +month - 1, +day, +hours, +minutes, +seconds));
}

function Counter(props) {
  const [timerDays, setTimerDays] = useState("00");
  const [timerHours, setTimerHours] = useState("00");
  const [timerMinutes, setTimerMinutes] = useState("00");
  const [timerSecounds, setTimerSecounds] = useState("00");
 

  let interval = useRef();

  const countdownDate = props.end_time == null?new Date("Nov 30, 2023, 00:00:00"): new Date(props.end_time_ts * 1000);

  const startTimer = () => {
    

    interval = setInterval(() => {

      const countdownDate = props.end_time == null?new Date("Nov 30, 2023, 00:00:00"): new Date(props.end_time_ts * 1000);

      const now = new Date().getTime();

      const distance = countdownDate - now;

      const days = Math.floor(
        distance / (1000 * 3600 * 24)
      );
 
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const secound = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        clearInterval(interval.current);
      } else {
        setTimerDays(days);
        setTimerHours(hours);
        setTimerMinutes(minutes);
        setTimerSecounds(secound);
      }
    }, 2000);
  };

  useEffect(() => {
    startTimer();

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      clearInterval(interval.current);
    };
  });
  return (
    <>
      <span id="hours1">{timerDays}</span>D :&nbsp;
      <span id="hours1">{timerHours}</span>H :&nbsp;
      <span id="minutes1">{timerMinutes}</span>M :&nbsp; 
      <span id="seconds1">{timerSecounds}</span>S 
    </>
  );
}

export default Counter;
