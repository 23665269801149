import React from "react";
import posthog from 'posthog-js';
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";








import Layout from "./components/App";
import Layout2 from "./components/layout/Layout2";
import Layout3 from "./components/layout/Layout3";
import MainLayout from "./components/layout/MainLayout";
import About from "./components/page/about/About";
import AuctionDetails from "./components/page/auctionDetails/AuctionDetails";
import Blog from "./components/page/blog/Blog";
import BlogDetails from "./components/page/BlogDetails/BlogDetails";
import Contact from "./components/page/contact/Contact";
import Dashboard from "./components/page/dashboard/Dashboard";
import Demo from "./components/page/demo/Demo";
import ErrorPage from "./components/page/error/ErrorPage";
import Faq from "./components/page/faq/Faq";
import ListAllAuctions from "./components/page/homepage2/ListAllAuctions";
import HowItWork from "./components/page/howItWork/HowItWork";
import HowToWin from "./components/page/howToWin/HowToWin";
import Merchant from "./components/page/joinMerchant/Merchant";
import LiveAuction from "./components/page/LiveAuction.jsx/LiveAuction";
import Login from "./components/page/login/Login";
import MakeMoney from "./components/page/makeMoney/MakeMoney";
import Reset from "./components/page/reset/Reset";

import SignUp from "./components/page/signUp/SignUp";

import Terms from "./components/page/terms/Terms";

import Welcome from "./components/page/welcome/Welcome";









import "./index.css"









//Default Warniing Error Hide
console.log = console.warn = console.error = () => {};

posthog.init('phc_pFRPr5XPxf5e0C0juIcIBnA1wKjAYBvbVxe5RDRkmnR', { api_host: 'https://ona.odebox.net' })

/*
=>version : 0.1
=>Event : Rendering al content to web
=>Action: define all routes and page
@return HTML
*/

function Root() {
  return <>
  <BrowserRouter basename="/">
    <Switch>
      <Route exact path="/" component={MainLayout} />
      <Route exact path="/index2" component={Layout2} />
      <Route exact path="/index3" component={Layout3} />
      <Layout>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/about`}
          component={About}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/contact`}
          component={Contact}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/error`}
          component={ErrorPage}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/signup`}
          component={SignUp}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/terms`}
          component={Terms}
        />


        <Route
          exact
          path={`${process.env.PUBLIC_URL}/login`}
          component={Login}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/auction-details/:slug`}
          component={AuctionDetails}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/password-reset/:token`}
          component={Reset}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/dashboard`}
          component={Dashboard}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/blog`}
          component={Blog}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/blog-details`}
          component={BlogDetails}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/live-auction`}
          component={LiveAuction}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/how-works`}
          component={HowItWork}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/how-to-win`}
          component={HowToWin}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/all-auctions`}
          component={ListAllAuctions}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/make-money`}
          component={MakeMoney}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/faq`}
          component={Faq}
        /> 
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/join-merchant`}
          component={Merchant}
        /> 

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/welcome`}
          component={Welcome}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/demo`}
          component={Demo}
        />
      </Layout> 
    </Switch>
  </BrowserRouter>
</>
}

export default Root;

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById("root")
);
