import React from 'react'
import ReactMarkdown from 'react-markdown';
import Moment from 'react-moment';
import { Link } from 'react-router-dom'

























function AuctionDetailsTab(props) {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" }) 

 
  const showBids = (e) => {
    if (props.bids.length > 0) {
      return (
        props.bids.map((item, i) => {
          return (
            <li key={i}>
              <div className="row d-flex align-items-center">
                <div className="col-7">
                  <div className="bidder-area">
                    <div className="bidder-img">
                      <img alt="images" height={90} src={process.env.PUBLIC_URL + "/images/bg/generic-avatar-small.png"}/>
                    </div>
                    <div className="bidder-content">
                      <Link to={"#"}><h6>{item.username}</h6></Link>
                      <p>~ KES {item.bid_value}</p>
                    </div> 
                  </div>
                </div>
                <div className="col-5 text-end">
                  <div className="bid-time">
                    <p><Moment fromNow>{item.created_at}</Moment></p>
                  </div>
                </div>
              </div>
            </li>
          )
        })
      )
    }
    else {
      return (
        <tr>
          <td colSpan="3">
            <div className="alert alert-danger" role="alert">
              <h6>No bids yet</h6>
            </div>
          </td>
        </tr>
      )
    }
  }

  const showLeaderboard = (e) => {
    if (props.leaderboard.length > 0) {
      return (
        props.leaderboard.map((item, i) => {
          return (
            <li key={i}>
              <div className="row d-flex align-items-center">
                <div className="col-7">
                  <div className="bidder-area">
                    <div className="bidder-img">
                      <img alt="images" height={90} src={process.env.PUBLIC_URL + "/images/bg/generic-avatar-small.png"}/>
                    </div>
                    <div className="bidder-content">
                      <Link to={"#"}><h6>{item.username}</h6></Link>
                      <p>Total rounds won: {item.count}</p>
                      <p>Total points won: {item.points}</p>
                    </div> 
                  </div>
                </div>
              </div>
            </li>
          )
        })
      )
    }
    else {
      return (
        <tr>
          <td colSpan="3">
            <div className="alert alert-danger" role="alert">
              <h6>No leaderboard yet</h6>
            </div>
          </td>
        </tr>
      )
    }
  }


  const showIcon = (ended) => {

    if (ended) {

      return (

        <i className="bi bi-check" />

      )

    }

    else {

      return (

        <i className="bi bi-alarm" />

      )

    }

  }

  const showRounds = (e) => {
    if (props.rounds.length > 0) {
      return (
        props.rounds.map((item, i) => {
          return (
            <li key={i}>
              <div className="row d-flex align-items-center">
                <div className="col-7">
                  <div className="bidder-area">
                    <div className="bidder-img">
                     
                      {showIcon(item.ended)}
                    </div>
                    <div className="bidder-content">

                      <p>
                        Started: 
                          <Moment fromNow>{item.started_at_ts * 1000}</Moment>
                      </p>
                      
                      <p>Ending:
                        <Moment fromNow>{item.ends_at_ts * 1000}</Moment>
                      
                      </p>

                      <h6>Winner: {item.winner?item.winner.username:'None'}</h6>
                    </div> 
                  </div>
                </div>
              </div>
            </li>
          )
        })
      )
    }
    else {
      return (
        <tr>
          <td colSpan="3">
            <div className="alert alert-danger" role="alert">
              <h6>No rounds yet</h6>
            </div>
          </td>
        </tr>
      )
    }
  }

  return (
    <>
      <div className="row d-flex justify-content-center g-6">
        <div className="col-lg-12">
          <ul className="nav nav-pills d-flex flex-row justify-content-start gap-sm-4 gap-3 mb-45 wow fadeInDown" data-wow-duration="1.5s" data-wow-delay=".2s" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
              <button className="nav-link active details-tab-btn" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Description</button>
            </li>
            <li className="nav-item" role="presentation">
              <button className="nav-link details-tab-btn" id="pills-bid-tab" data-bs-toggle="pill" data-bs-target="#pills-bid" type="button" role="tab" aria-controls="pills-bid" aria-selected="false">Latest Bids</button>
            </li>

            <li className="nav-item" role="presentation">
              <button className="nav-link details-tab-btn" id="pills-leaderboard-tab" data-bs-toggle="pill" data-bs-target="#pills-leaderboard" type="button" role="tab" aria-controls="pills-leaderboard" aria-selected="false">Leaderboard</button>
            </li>

            <li className="nav-item" role="presentation">
              <button className="nav-link details-tab-btn" id="pills-rounds-tab" data-bs-toggle="pill" data-bs-target="#pills-rounds" type="button" role="tab" aria-controls="pills-rounds" aria-selected="false">All Rounds</button>
            </li>

          </ul>
          <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade show active wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".2s" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
              <div className="describe-content">
                <ReactMarkdown>{props.product_description_long}</ReactMarkdown>
              </div>
            </div>

            <div className="tab-pane fade" id="pills-bid" role="tabpanel" aria-labelledby="pills-bid-tab">
              <div className="bid-list-area">
                <ul className="bid-list">
                  {showBids()}
                </ul>
              </div>
            </div>

            <div className="tab-pane fade" id="pills-leaderboard" role="tabpanel" aria-labelledby="pills-leaderboard-tab">
              <div className="bid-list-area">
                <ul className="bid-list">
                  {showLeaderboard()}
                </ul>
              </div>
            </div>

            <div className="tab-pane fade" id="pills-rounds" role="tabpanel" aria-labelledby="pills-rounds-tab">
              <div className="bid-list-area">
                <ul className="bid-list">
                  {showRounds()}
                </ul>
              </div>
            </div>


          </div>
        </div>

      </div>
    </>
  )
}

export default AuctionDetailsTab