import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import Counter from '../../common/Counter'
















function AuctionCard(props){
  const scrollTop =()=>window.scrollTo({top:0,behavior:"smooth"});

  const showCOmpletedMark = (e)=>{
    if(props.completed){
      return(
          <div>
            <span className="badge bg-danger">Ended</span>
          </div>
          
      
      )
    }
    else {
      return(
        <div>
          <span className="badge bg-success">Live</span>
        </div>
      )
    }
  }

    return(
        <>
        <div data-wow-duration="1.5s" data-wow-delay="0.2s" className="eg-card auction-card2 wow fadeInDown">
                <div className="auction-img">
                  
                    <Link to={`${process.env.PUBLIC_URL}/auction-details/${props.slug}`} onClick={scrollTop}>
                    <img alt="liveAuctionImage" src={`${process.env.PUBLIC_URL} ${props.image}`} className="fillheight"/>
                    </Link>
                  
                  <div className="auction-timer">
                    <div className="countdown" id="timer1">
                      <h5><Counter end_time={props.end_time} end_time_ts={props.end_time_ts}/></h5>
                    </div>
                  </div>
                </div>
                <div className="auction-content">
                  <h4><Link to={`${process.env.PUBLIC_URL}/auction-details/${props.slug}`} onClick={scrollTop}>{props.title} {showCOmpletedMark()}</Link></h4>
                  <div className="author-price-area">
                    
                    <p>
                    <Link to={`${process.env.PUBLIC_URL}/auction-details/${props.slug}`} onClick={scrollTop}>
                    {props.completed?'Final Price': 'Current Price'} : KES {props.price}

                   
                    </Link>
                      
                    </p>
                  </div>

                  <div className="auction-card-bttm">
                    <Link to={`${process.env.PUBLIC_URL}/auction-details/${props.slug}`} onClick={scrollTop} className="eg-btn btn--primary2 btn--sm">

                    {props.completed?'View Auction':'Place Bid'}
                    </Link>
                    
                  </div>
                </div>
              </div>
        </>
    )
}



function AllAuctionArea(props) {
  const scrollTop =()=>window.scrollTo({top:0,behavior:"smooth"});

  const showPastAuctionItems =  (e)=> {
    if(props.pastAuctions.length > 0){
      return(
        props.pastAuctions.map((item, i) => {
          return(
            <div className="col-lg-4 col-md-6 col-sm-10">
              <AuctionCard key={i} image={item.featured_image} title={item.product_name} price={item.current_price} slug={item.slug} end_time={item.end_time} end_time_ts={item.end_time_ts} completed={item.completed} />
            </div>
            
          )
        })
      )
    }
    else{
      return(
        <div className="col-12">
          <div className="alert alert-danger" role="alert">
            <h6>No past auctions to display at the moment</h6>
          </div>
        </div>
      )
    }
  }

  return (
    <>
     <div className="live-auction pb-120">
        <img alt="auctioniImage" src={process.env.PUBLIC_URL +  "/images/bg/section-bg2.png"} className="img-fluid section-bg2" />
        
        <br/>

        <div className="container position-relative">
          <img alt="auctioniImage" src={process.env.PUBLIC_URL +  "/images/bg/dotted2.png"} className="dotted3" />
          <div className="row d-flex justify-content-center align-items-center mb-60 g-4">
            <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6">
              <div className="section-title2 text-lg-start text-center">
                <h2>Past Auctions</h2>
                <p className="mb-0">Explore our previous auctions and some of out past winners. With everything from electronics to home appliances and shopping, we have everything you need.</p>
              </div>
            </div>
            <div className="col-xl-6 col-lg-4 col-xl-6 text-lg-end text-right">
              
            </div>
          </div>
          <div id='auction-list-cont' className="row gy-4 d-flex justify-content-center">
            
               {showPastAuctionItems()}  
           
            
          </div>
        </div>
        
      </div>   
    </>
  )
}

export default AllAuctionArea