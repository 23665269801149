import React from 'react'
import {Link} from 'react-router-dom'
function HeroBanner() {
  const scrollToAcution = () => {
    window.scrollTo({top:680,behavior:"smooth"});
  }

  return (
    <>
      <div className="hero-area hero-style-two">
        <img alt="heroImage" src={process.env.PUBLIC_URL + "/images/bg/section-bg2.png"} className="radial-bg" />
        <img alt="heroImage" src={process.env.PUBLIC_URL + "/images/bg/banner2-icon1.png"} className="banner2-icon1" />
        <img alt="heroImage" src={process.env.PUBLIC_URL + "/images/bg/banner2-icon2.png"} className="banner2-icon2" />
        <img alt="heroImage" src={process.env.PUBLIC_URL + "/images/bg/banner2-icon3.png"} className="banner2-icon3" />
        <div className="scroll-text">
          <h6><a href="#category">Shukisha</a></h6>
        </div>
        <div className="container">
          <div className="row d-flex justify-content-start align-items-end">
            <div className="col-xl-7 col-lg-7">
              <div className="banner2-content">
                <span className="wow fadeInDown" data-wow-duration="1.5s" data-wow-delay="0.5s">Welcome To Shukisha</span>
                <h1 className="wow fadeInDown" data-wow-duration="1.5s" data-wow-delay="1s">Bid, win &amp; collect electronic items.</h1>
                <p className="wow fadeInUp bannerdec" data-wow-duration="1.5s" data-wow-delay="1s"> You are probably familiar with online auctions where the highest bid wins. Here we're doing it different.
                  When the timer runs out, the lowest unique bid wins! You could get that iphone for fraction of retail price.
                </p>
                <Link to={`${process.env.PUBLIC_URL}`} onClick={scrollToAcution} className="eg-btn btn--primary2 btn--lg wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.8s">Start Bidding</Link>
              </div>
            </div>
          </div>
        </div>
      </div>   
    </>
  )
}

export default HeroBanner