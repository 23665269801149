import React from 'react'
import { Link } from 'react-router-dom'

function WhyCHooseUs() {
    const scrollTop = ()=>window.scrollTo({top:0,behavior:"smooth"})
  return (
    <>
      <div className="choose-us-section pb-120">
        <img alt="images" src={process.env.PUBLIC_URL + "/images/bg/section-bg.png"} className="section-bg-bottom" /> 
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6">
              <div className="section-title1">
                <h2>Why Choose Us</h2>
                <p className="mb-0">Explore on the world's best &amp; largest Bidding marketplace with our beautiful Bidding
                  products. We want to be a part of your smile, success and future growth.</p>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center g-4">
            <div className="col-lg-4 col-md-6 col-sm-10">
              <div className="single-feature hover-border1 wow fadeInDown" data-wow-duration="1.5s" data-wow-delay=".2s">
                <span className="sn">01</span>
                <div className="icon">
                  <svg width={68} height={68} viewBox="0 0 68 68" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.1145 48.0982C16.1114 48.0982 16.1082 48.0982 16.1034 48.0982C15.58 48.0919 15.1594 47.6633 15.1641 47.1399C15.1989 43.917 17.8904 41.2935 20.2657 38.9799C21.9957 37.2941 23.7843 35.5498 23.6103 34.299C23.362 32.5151 21.5181 31.4034 19.5651 30.2253C19.3232 30.0798 19.0812 29.9343 18.8424 29.7872C18.3965 29.5121 18.2573 28.927 18.5325 28.481C18.8076 28.0351 19.3912 27.8959 19.8387 28.1711C20.0727 28.315 20.3084 28.4573 20.5456 28.6012C22.6884 29.8932 25.1174 31.3591 25.4906 34.038C25.7974 36.2409 23.7542 38.2319 21.5909 40.3399C19.4797 42.3973 17.0887 44.7283 17.0618 47.162C17.057 47.6807 16.6332 48.0982 16.1145 48.0982Z" />
                    <path d="M32.8288 58.4831C27.6782 58.4831 22.8865 56.8353 19.0184 53.6757C18.4744 53.2313 17.9795 52.7758 17.5477 52.3236C17.1856 51.944 17.1998 51.3431 17.5794 50.9826C17.9589 50.6204 18.5598 50.6346 18.9204 51.0142C19.2983 51.4111 19.7364 51.8112 20.2203 52.2081C24.7352 55.8959 30.6575 57.3224 36.8977 56.2249C43.1901 55.1179 49.0444 51.5835 53.3837 46.2716C62.306 35.3505 61.9486 20.2402 52.5867 12.591C44.2164 5.75306 31.0497 7.12887 21.9598 15.7902C21.5803 16.1523 20.9794 16.1365 20.6188 15.7585C20.2567 15.379 20.2725 14.7781 20.652 14.4175C25.3598 9.93269 31.2774 7.10673 37.3152 6.46311C43.4573 5.80841 49.3085 7.46255 53.7902 11.1235C63.9633 19.4353 64.4409 35.7411 54.856 47.4734C50.2304 53.1364 43.9697 56.9096 37.2298 58.0957C35.7417 58.3534 34.271 58.4831 32.8288 58.4831Z" />
                    <path d="M47.5548 39.5697C46.2533 39.5697 44.9423 39.1379 43.8575 38.2524C41.3589 36.2108 40.9873 32.5182 43.0288 30.0196C45.0704 27.521 48.763 27.1494 51.2616 29.191C53.7602 31.2325 54.1318 34.9251 52.0902 37.4237C50.9358 38.8359 49.2516 39.5697 47.5548 39.5697ZM45.0578 36.7817C46.7451 38.1606 49.2421 37.9092 50.6211 36.2219C52.0001 34.5345 51.7487 32.0391 50.0613 30.6585C48.374 29.2795 45.8769 29.531 44.498 31.2183C43.119 32.9072 43.3704 35.4027 45.0578 36.7817Z" />
                    <path d="M33.5093 52.2602C31.9833 52.2602 30.5173 51.74 29.3202 50.7611C27.946 49.6383 27.092 48.049 26.9133 46.2826C26.7346 44.5177 27.2565 42.7893 28.3777 41.415C30.6944 38.578 34.8867 38.1574 37.7237 40.4741C39.098 41.5969 39.9519 43.1862 40.1306 44.9526C40.3077 46.7175 39.7874 48.4459 38.6662 49.8202C37.5434 51.1944 35.9541 52.0483 34.1877 52.227C33.96 52.2492 33.7339 52.2602 33.5093 52.2602ZM33.5251 40.8758C32.1493 40.8758 30.783 41.4704 29.8468 42.6169C29.045 43.5989 28.6734 44.8324 28.7999 46.0944C28.9264 47.3548 29.5368 48.4918 30.5189 49.2935C31.5009 50.0953 32.7344 50.4669 33.9964 50.3404C35.2567 50.2139 36.3938 49.6035 37.194 48.6215C37.9957 47.6394 38.3673 46.4059 38.2408 45.144C38.1143 43.8836 37.5039 42.7466 36.5219 41.9448C35.6442 41.2253 34.5815 40.8758 33.5251 40.8758Z" />
                    <path d="M44.4198 23.7873C43.2939 23.7873 42.1616 23.4141 41.2238 22.6471C39.0637 20.8823 38.741 17.6879 40.5059 15.5261C42.2707 13.3659 45.4651 13.0433 47.6269 14.8081C49.7871 16.573 50.1097 19.7674 48.3449 21.9292C47.3438 23.1547 45.8874 23.7873 44.4198 23.7873ZM44.4277 15.5688C43.5105 15.5688 42.5997 15.9641 41.975 16.7295C40.8712 18.08 41.072 20.0758 42.4225 21.1796C43.773 22.2834 45.7688 22.0826 46.8726 20.732C47.9764 19.3815 47.7756 17.3858 46.425 16.282C45.8384 15.8012 45.1315 15.5688 44.4277 15.5688Z" />
                    <path d="M28.9452 26.2243C27.9647 26.2243 27.0206 25.889 26.252 25.2612C25.3696 24.5401 24.8193 23.5169 24.7054 22.3831C24.5916 21.2476 24.9253 20.1375 25.648 19.2535C27.1376 17.4301 29.8323 17.1581 31.6557 18.6478C32.5381 19.3689 33.0884 20.3921 33.2023 21.5259C33.3161 22.6614 32.9825 23.7715 32.2598 24.6555C31.5386 25.5379 30.5155 26.0883 29.3816 26.2021C29.2361 26.2179 29.0906 26.2243 28.9452 26.2243ZM28.9562 19.584C28.2683 19.584 27.5852 19.8813 27.1171 20.4538C26.7154 20.944 26.5304 21.5623 26.5936 22.1917C26.6569 22.8227 26.9621 23.3904 27.4523 23.7905C27.9426 24.1922 28.5593 24.3772 29.1903 24.3139C29.8212 24.2507 30.389 23.9455 30.7891 23.4552C31.1907 22.965 31.3758 22.3467 31.3125 21.7173C31.2492 21.0863 30.944 20.5186 30.4538 20.1185C30.0142 19.7579 29.4828 19.584 28.9562 19.584Z" />
                    <path d="M15.2209 47.5067C15.1228 47.5067 15.0232 47.4909 14.9236 47.4593C14.4254 47.2948 14.155 46.7587 14.3195 46.2606C14.952 44.3407 15.6083 41.9623 16.2709 39.1917C17.1265 35.6162 17.9551 31.5077 18.6699 27.3091C19.6915 21.2903 20.411 15.4676 20.6957 10.9164C20.9566 6.73042 20.7842 4.77898 20.615 3.89814C20.1643 4.67302 19.3578 6.45842 18.2224 10.4957C16.9889 14.8857 15.7459 20.6182 14.7227 26.6386C13.4228 34.2862 12.6163 41.6587 12.5657 46.3586C12.5594 46.8789 12.1356 47.2964 11.6169 47.2964C11.6137 47.2964 11.6106 47.2964 11.6058 47.2964C11.0824 47.29 10.6617 46.8615 10.668 46.3365C10.7218 41.4705 11.5172 34.1755 12.8519 26.3192C13.9716 19.7311 15.3284 13.5826 16.6758 9.00763C17.3399 6.75256 17.9678 4.99088 18.545 3.76847C19.1253 2.53814 19.8749 1.30782 21.0214 1.50233C22.1679 1.69842 22.4684 3.10744 22.6123 4.45954C22.7546 5.80214 22.7657 7.67451 22.6487 10.0213C22.4099 14.7845 21.6619 21.0357 20.5423 27.6254C19.8212 31.8651 18.983 36.0163 18.118 39.6313C17.4427 42.4526 16.7722 44.8816 16.1223 46.852C15.991 47.2537 15.621 47.5067 15.2209 47.5067Z" />
                    <path d="M13.2958 66.5135C13.2373 66.5135 13.1787 66.5088 13.1218 66.4977C10.8794 66.0786 8.91372 63.9849 7.58693 60.5991C6.44674 57.6925 5.98814 54.2545 6.38823 51.6247C6.7219 49.4281 7.91428 47.4814 9.65856 46.2843C11.1261 45.277 12.804 44.9132 14.3838 45.2611C17.8533 46.025 19.9155 50.0955 18.9809 54.3352C18.6013 56.0558 17.7933 57.3715 16.9377 58.7647C15.8102 60.6007 14.6447 62.4984 14.2367 65.6865C14.2035 65.9458 14.0659 66.1799 13.854 66.3333C13.6895 66.4519 13.495 66.5135 13.2958 66.5135ZM13.2009 47.0292C12.3691 47.0292 11.5151 47.3091 10.7307 47.8467C9.41818 48.7481 8.51837 50.2283 8.26218 51.9093C7.90953 54.2277 8.32702 57.2924 9.35176 59.9065C10.201 62.073 11.3301 63.6085 12.5399 64.2869C13.1107 61.3692 14.2762 59.47 15.32 57.7716C16.1233 56.4638 16.816 55.3346 17.1275 53.9272C17.836 50.7091 16.4222 47.6538 13.9758 47.1145C13.7212 47.0576 13.4618 47.0292 13.2009 47.0292Z" />
                    <path d="M17.1971 40.3618C17.1401 40.3618 17.0816 40.3571 17.0231 40.346L11.9864 39.4114C11.4708 39.3165 11.1308 38.82 11.2273 38.306C11.3238 37.7921 11.8187 37.4505 12.3327 37.5454L17.3694 38.48C17.885 38.5749 18.225 39.0714 18.1285 39.5854C18.0447 40.0424 17.6462 40.3618 17.1971 40.3618Z" />
                  </svg>
                </div>
                <div className="content">
                  <h5><Link to={`${process.env.PUBLIC_URL}/live-auction`} onClick={scrollTop}>High Quality Products</Link></h5>
                  <p className="para">We source merchandise only from the most trusted suppliers thus we can bet our lives on the quality of the products. </p>
                </div>
              </div>
            </div>
            
            <div className="col-lg-4 col-md-6 col-sm-10">
              <div className="single-feature hover-border1 wow fadeInDown" data-wow-duration="1.5s" data-wow-delay=".6s">
                <span className="sn">02</span>
                <div className="icon">
                  <svg width={68} height={68} viewBox="0 0 68 68" xmlns="http://www.w3.org/2000/svg">
                    <path d="M29.6543 22.72H15.8108C12.9706 22.72 10.6602 20.4096 10.6602 17.5694V17.0997C10.6602 14.2611 12.9706 11.9507 15.8108 11.9507H38.3978C41.238 11.9507 43.5484 14.2611 43.5484 17.0997V17.3401C43.5484 17.8635 43.123 18.2889 42.5996 18.2889C42.0762 18.2889 41.6508 17.8635 41.6508 17.3401V17.0997C41.6508 15.3064 40.1911 13.8484 38.3978 13.8484H15.8108C14.0175 13.8484 12.5578 15.3064 12.5578 17.0997V17.5694C12.5578 19.3627 14.0175 20.8223 15.8108 20.8223H29.6543C30.1777 20.8223 30.6031 21.2477 30.6031 21.7711C30.6031 22.2946 30.1777 22.72 29.6543 22.72Z" />
                    <path d="M23.774 31.5916H11.485C8.64636 31.5916 6.33594 29.2811 6.33594 26.441V25.9729C6.33594 23.1327 8.64636 20.8223 11.485 20.8223H29.6536C30.1771 20.8223 30.6024 21.2477 30.6024 21.7711C30.6024 22.2945 30.1771 22.7199 29.6536 22.7199H11.485C9.69166 22.7199 8.23361 24.1796 8.23361 25.9729V26.441C8.23361 28.2343 9.69166 29.6939 11.485 29.6939H23.774C24.2974 29.6939 24.7228 30.1193 24.7228 30.6427C24.7228 31.1662 24.2974 31.5916 23.774 31.5916Z" />
                    <path d="M23.2261 40.4631H18.0217C17.9632 40.4631 17.9031 40.4616 17.8446 40.46C15.0566 40.3683 12.8711 38.1069 12.8711 35.3125V34.8444C12.8711 32.0043 15.1815 29.6938 18.0217 29.6938H23.7717C24.2951 29.6938 24.7205 30.1192 24.7205 30.6427C24.7205 31.1661 24.2951 31.5915 23.7717 31.5915H18.0217C16.2284 31.5915 14.7688 33.0511 14.7688 34.8444V35.3125C14.7688 37.0774 16.1477 38.5054 17.9094 38.5623C17.949 38.5639 17.9853 38.5655 18.0217 38.5655H23.2261C23.7495 38.5655 24.1749 38.9909 24.1749 39.5143C24.1749 40.0378 23.7495 40.4631 23.2261 40.4631Z" />
                    <path d="M27.3843 49.3316H11.485C8.64636 49.3316 6.33594 47.0211 6.33594 44.181V43.7129C6.33594 40.8727 8.64636 38.5623 11.485 38.5623H23.2268C23.7503 38.5623 24.1757 38.9877 24.1757 39.5111C24.1757 40.0345 23.7503 40.4599 23.2268 40.4599H11.485C9.69166 40.4599 8.23361 41.9196 8.23361 43.7129V44.181C8.23361 45.9743 9.69166 47.4339 11.485 47.4339H27.3843C27.9078 47.4339 28.3331 47.8593 28.3331 48.3827C28.3331 48.9062 27.9078 49.3316 27.3843 49.3316Z" />
                    <path d="M41.8292 56.0493C30.8907 56.0493 21.9922 47.1508 21.9922 36.2123C21.9922 25.2738 30.8907 16.3752 41.8292 16.3752C52.7677 16.3752 61.6662 25.2738 61.6662 36.2123C61.6662 47.1508 52.7677 56.0493 41.8292 56.0493ZM41.8292 18.2713C31.9376 18.2713 23.8899 26.3191 23.8899 36.2107C23.8899 46.1023 31.9376 54.1501 41.8292 54.1501C51.7208 54.1501 59.7686 46.1023 59.7686 36.2107C59.7686 26.3191 51.7208 18.2713 41.8292 18.2713Z" />
                    <path d="M41.8282 50.4703C33.9671 50.4703 27.5703 44.0736 27.5703 36.2124C27.5703 28.3513 33.9671 21.9546 41.8282 21.9546C49.6893 21.9546 56.086 28.3513 56.086 36.2124C56.086 44.0736 49.6893 50.4703 41.8282 50.4703ZM41.8282 23.8523C35.0124 23.8523 29.468 29.3966 29.468 36.2124C29.468 43.0283 35.0124 48.5726 41.8282 48.5726C48.644 48.5726 54.1884 43.0283 54.1884 36.2124C54.1884 29.3966 48.6424 23.8523 41.8282 23.8523Z" />
                    <path d="M35.5909 37.3668H33.6442C33.1207 37.3668 32.6953 36.9414 32.6953 36.418C32.6953 35.8945 33.1207 35.4691 33.6442 35.4691H35.5909C35.8028 35.4691 36.0083 35.3932 36.1538 35.262C36.2234 35.1987 36.3404 35.0643 36.3404 34.8808C36.3404 34.5614 35.9973 34.291 35.5909 34.291H33.6442C33.1207 34.291 32.6953 33.8656 32.6953 33.3421C32.6953 32.8187 33.1207 32.3933 33.6442 32.3933H35.5909C37.0505 32.3933 38.2381 33.5082 38.2381 34.8808C38.2381 35.5593 37.9503 36.195 37.4269 36.6694C36.9303 37.1201 36.2788 37.3668 35.5909 37.3668Z" />
                    <path d="M33.6442 40.03C33.1207 40.03 32.6953 39.6046 32.6953 39.0812V33.3439C32.6953 32.8204 33.1207 32.395 33.6442 32.395C34.1676 32.395 34.593 32.8204 34.593 33.3439V39.0812C34.593 39.6046 34.1692 40.03 33.6442 40.03Z" />
                    <path d="M43.7683 40.0298H40.109C39.5856 40.0298 39.1602 39.6044 39.1602 39.0809V33.3436C39.1602 32.8202 39.5856 32.3948 40.109 32.3948C40.6324 32.3948 41.0578 32.8202 41.0578 33.3436V38.1321H43.7683C44.2918 38.1321 44.7172 38.5575 44.7172 39.0809C44.7172 39.6044 44.2918 40.0298 43.7683 40.0298Z" />
                    <path d="M50.1661 40.0298C49.8514 40.0298 49.5494 39.8732 49.3707 39.598L47.3781 36.5396V39.0809C47.3781 39.6044 46.9527 40.0298 46.4293 40.0298C45.9059 40.0298 45.4805 39.6044 45.4805 39.0809V33.3436C45.4805 32.923 45.7572 32.5529 46.1589 32.4343C46.5621 32.3157 46.9954 32.4738 47.2248 32.8265L49.2173 35.8849V33.3436C49.2173 32.8202 49.6427 32.3948 50.1661 32.3948C50.6896 32.3948 51.115 32.8202 51.115 33.3436V39.0809C51.115 39.5016 50.8382 39.8716 50.4366 39.9902C50.3464 40.0171 50.2563 40.0298 50.1661 40.0298Z" />
                  </svg>
                </div>
                <div className="content">
                  <h5><Link to={`${process.env.PUBLIC_URL}/live-auction`} onClick={scrollTop}>Top-class Prices</Link></h5>
                  <p className="para">Our prices are more than competitive internationally and unmatched locally, customer satisfaction is guaranteed. </p>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>   
    </>
  )
}

export default WhyCHooseUs