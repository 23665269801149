import React from "react";
import { Link } from "react-router-dom";































function MakeMoneyContent() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  return (
    <>
      <div className="how-work-section pt-120 pb-120">
        <img
          alt="images"
          src={process.env.PUBLIC_URL + "/images/bg/section-bg.png"}
          class="section-bg-top"
        />
        <div className="container">

          <div className="row g-4 mb-60">
            
            
            <div className="col-xl-6 col-lg-6">
              <div
                className="how-work-content wow fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay=".2s"
              >
                <span>01.</span>
                <h3>Register An Account</h3>
                <p className="para">
                  Before you become an affiliate you need to register an account. This is a simple process and only takes a few minutes. Once you have registered you can start making money.
                </p>

                <Link
                  to={`${process.env.PUBLIC_URL}/signup`}
                  onClick={scrollTop}
                  className="eg-btn btn--primary btn--md"
                >
                  Register Account
                </Link>
                 
              </div>
            </div>

            <div className="col-xl-6 col-lg-6">
              <div
                className="how-work-content wow fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay=".2s"
              >
                <span>02.</span>
                <h3>Activate Your Affiliate Account</h3>
                <p className="para">
                  Once you have registered, you need to go to the 'My Account' section and find the 'Affiliates' tab. Here you can activate your affiliate account. Once you have activated your account you can start making money.
                  You will get a referral link that you can share with your friends and family.
                </p>
                
                
              </div>

            </div>

            <div className="col-xl-6 col-lg-6">
              <div
                className="how-work-content wow fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay=".2s"
              >
                <span>03.</span>
                <h3>Promote and Earn</h3>
                <p className="para">
                  Once someone clicks on your referral link and signs up, you will earn 10% of their deposits on the platform for up to 90 days after they sign up. You will get paid on the 28th of every month with your referral earnings sent directly to your M-PESA account.
                </p>
                
                
              </div>
            </div>
            
            
          </div>

          
          


        </div>
      </div>
    </>
  );
}

export default MakeMoneyContent;
