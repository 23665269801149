import React, { useState, useEffect } from 'react';
import posthog from 'posthog-js';
import { Link , useHistory} from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';


import Preloader from "../../layout/Preloader";

import {fetchPostJSON, userLoginJSON} from '../../../common/services';

import {saveCurrentToken, saveUserInfo} from '../../../common/data_methods'


function ResetWrap(props) {
  const [openEye, setOpenEye]= useState(1);

  const [phone, setPhone]= useState();

  const [password, setPassword]= useState();

  const [success, setSucces]= useState(false);

  const [resetSubmitSuccess, setResetSubmitSuccess]= useState(false);

  const [error, setError]= useState(false);

  const [isBusy, setIsBusy]= useState(true);

  const [resetPrompt, setResetPrompt] = useState(false);

  const [result, setResult]= useState();

  const history = useHistory()


  const doRequestReset = async (token)=>{

    setIsBusy(true);

    const res = await fetchPostJSON('/users/confirm_reset', {token: token}, true);

    setIsBusy(false);

    if(res.Ok){
      setResetSubmitSuccess(true);
    }
    else{
      setError(true);
      setResult(res.data);
    }
  }

  const doHandleSubmit = async (e)=>{
    setError(false);
    setResetSubmitSuccess(false);
    history.push('/login'); 
  }

  const showPromptSuccess =  (e)=> {
    if(resetSubmitSuccess === true || resetSubmitSuccess === 1){
      return(
        <SweetAlert
          success
          title="Reset successful"
          onConfirm={() => doHandleSubmit(false)}
        >
          You have successfully reset your password. You should be receiving the new login info shortly.
        </SweetAlert>
      )
    }
  }


  const showError =  (e)=> {
    if(error === true || error === 1){
      return(
        <SweetAlert
          danger
          title="Reset Failed"
          onConfirm={() => doHandleSubmit(false)}
        >
          {result}
        </SweetAlert>
      )
    }
  }

  const showLoader =  (e)=> {
    if(isBusy === false || isBusy ===0){

    }else{
      return(
        <Preloader styles="preloader" />
      )
    }
   
  }

  useEffect(() => {

    if(props.token){
      doRequestReset(props.token)
    }

    else{
      history.push('/login'); 
    }
  }, []);


  return (
    <>
      <div className="login-section pt-120 pb-120">
        
      {showLoader()}
              {showError()}
              {showPromptSuccess()}
      </div>   
    </>
  )
}

export default ResetWrap