import React from "react";
import { useState, useEffect } from "react";
import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";

import {isUserLoggedIn} from '../../../common/data_methods';
import {fetchDataJSON} from '../../../common/services';
import Preloader from "../../../components/layout/Preloader";
import AboutUsCounter from "../../common/AboutUsCounter";
import AllAuctionsArea from "./AllAuctionsArea";
import CategoryHome2 from "./CategoryHome2";
import HeroBanner from "./HeroBanner";

function ListAllAuctions(props) {

    const [loading, setLoading] = useState(false);

    const [pastAuctions, setPastAuctions] = useState([]);

    const doLoadData = async (e)=>{
        setLoading(true);
    
        const pastRes = await fetchDataJSON('/auctions/list/past_all', isUserLoggedIn());
    
        if(pastRes.Ok){
          setPastAuctions(pastRes.data.auctions);
        }
        setLoading(false);
    }

    useEffect(() => {
        doLoadData();
    
      }, []);

  return (
    <>

    {loading ? (
        <Preloader styles="preloader" />
      ) : (
        <>
        <br/><br/><br/><br/>
        <AllAuctionsArea id="lva"  pastAuctions={pastAuctions}/>
        </>
      )}
    
    </>
  );
}

export default ListAllAuctions;
