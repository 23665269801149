import React from 'react'
import AboutUsCounter from '../../common/AboutUsCounter'
import Breadcrumb from '../../common/Breadcrumb'
import DashbordWrap from './DashboardWrap'
import {isUserLoggedIn} from '../../../common/data_methods';
import { Link , useHistory} from 'react-router-dom';

function Dashboard() {
  const history = useHistory();

  if(!isUserLoggedIn()){
    history.push('/login');
  } 
  
  return (
    <>
     <Breadcrumb pageName="Dashboard" pageTitle="Dashboard"/> 
     <DashbordWrap/>
    </>
  )
}
 
export default Dashboard