import React from "react";

import Breadcrumb from "../../common/Breadcrumb";
import WelcomeContent from "./WelcomeContent";

function Welcome() {
  return (
    <>
      <Breadcrumb pageName="Welcome" pageTitle="Welcome to Shukisha" />
      <WelcomeContent/>
    </>
  );
}

export default Welcome;
