import React from 'react'
import { useState, useEffect } from "react";
import { Link } from 'react-router-dom'
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {fetchDataJSON, fetchPostJSON} from '../../../common/services';
import Preloader from "../../../components/layout/Preloader";
import { orderListOptions } from '../../../data/data'

function ContentOfAffiliate(props) {

  const showTableRows = () => {

    return props.affiliate_commisions.map((item, index) => {
      return (
        <tr key={index}>
          <td>{item.username}</td>
          <td>{item.amount}</td>
          <td>{item.amount * 0.1}</td>
          <td>{item.created_at}</td>
          <td>OK</td>
        </tr>
      )
    })
  }

  const showTable = () => {

    if(props.affiliate_info.hasOwnProperty('id')){
      const link = "https://www.shukisha.com?safid=" + props.affiliate_info.identity;
    
            return (
                <>
                 
                    <div className="table-title-area">
                      <h3>Affiliate Commissions For Period {props.affiliate_period}</h3>
                    <br/>
                      <span onClick={() => {navigator.clipboard.writeText(link); toast.success("Text copied", {position: toast.POSITION.TOP_CENTER});}}>My Link: {link} <i className="fa fa-copy"></i></span>
                      
                    </div>
                    {/* table */}
                    <div className="table-wrapper">
                      <table className="eg-table order-table table mb-0">
                        <thead>
                          <tr>
    
                           <th>Username</th>
                            <th>Amount</th>
                            <th>Commision</th>
                            
                            <th>Date</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {showTableRows()}
                        </tbody>
                      </table>
                        <br/>
                      <span>* figures are subject to verification before payout on the 28th of each month.</span>
                    </div>
                    {/* pagination area */}
                    
       
                </>
              )

        

        
    }
    else {
        return (
            <>
            <div>
                <h3>You are not an affiliate</h3>

                <p>Join our affiliate program and earn up to 10% commission on every purchase made by your referrals for up to 90 days after they sign up.</p>

                <Link to="#" className="btn btn-primary" onClick={props.handleJoin}>Join Affiliate Program</Link>
            </div>
            </>
        )
    }
};

  const customStyle = {      
    
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: '#EEEEEE',
      padding:0,
      '&:hover': { borderColor: '#32c36c' },
      boxShadow: state.isFocused ? null : null,
  
    }),            
  }
  return (
    <>
    <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        
     <div className="tab-pane fade" id="v-pills-affiliate" role="tabpanel" aria-labelledby="v-pills-affiliate-tab">
        {/* table title*/}
        {showTable()}
        
      </div>   
    </>
  )
}

export default ContentOfAffiliate