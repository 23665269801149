import React from "react";
import { useState, useEffect } from "react";











import {isUserLoggedIn, saveCurrentAffiliateId} from '../../common/data_methods';
import {fetchDataJSON} from '../../common/services';
import Footer from "../common/Footer";
import Header from "../common/Header";

import HomePageTwo from "../page/homepage2/HomePageTwo";

import Preloader from "./Preloader";










function MainLayout(props) {
  const [loading, setLoading] = useState(false);

  const [activeAuctions, setActiveAuctions] = useState([]);

  const [pastAuctions, setPastAuctions] = useState([]);
 
  const doLoadData = async (e)=>{
    setLoading(true);

    const res = await fetchDataJSON('/auctions/list/active', isUserLoggedIn());

    const pastRes = await fetchDataJSON('/auctions/list/past', isUserLoggedIn());

    if(res.Ok){
      setActiveAuctions(res.data.auctions);
    }

    if(pastRes.Ok){
      setPastAuctions(pastRes.data.auctions);
    }

    // console.log(res.data);
    setLoading(false);
  }

  useEffect(() => {

    const query = new URLSearchParams(props.location.search);

    const token = query.get('safid')
    // alert(token)//123

    if(token){
      saveCurrentAffiliateId(token);
    }
    /*setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 700);*/
    doLoadData();

  }, []);

  return (
    <>
      {loading ? (
        <Preloader styles="preloader" />
      ) : (
        <>
          <Header />
          <HomePageTwo activeAuctions={activeAuctions} pastAuctions={pastAuctions}/>
          <Footer />
        </>
      )}
    </>
  );
}

export default MainLayout;