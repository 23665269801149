import React from 'react'
import { Link } from 'react-router-dom'

function WhoWeAreArea() {
  return (
    <>
      <div className="about-section pt-120 pb-120 overflow-hidden">
        <img src={process.env.PUBLIC_URL + "/images/bg/section-bg.png"} className="img-fluid section-bg-top" alt="section-bg" />
        <div className="container">
          <div className="row d-flex justify-content-center g-4">
            
            <div className="col-lg-10 col-md-10">
              <div className="about-content wow fadeInDown" data-wow-duration="1.5s" data-wow-delay=".2s">
                <span>Terms of Service</span>
                <h2>How to use the platform</h2>

                <p className="para">
                 
Arucy Enterprises, a company registered under the laws of Kenya whose registered address is: P. O. Box 446 - 
00511 (hereinafter referred to as Shukisha). 
This agreement establishes the Terms and Conditions that govern the contractual relationship between the Company 
and the Auction Bidder and shall apply when a Bidder bids in the Shukisha auction, whether live, or via any media 
or electronic platform, including inter alia, mobile devices, tablets, laptops and computers. Each type of auction may 
also have its own auction-specific Procedures and Rules that apply, and bidders are reminded that they will be 
deemed to have agreed to and be bound by such Procedures and Rules. Bidders are required to accept these Terms 
and Conditions when interacting with the services offered by the Company and are bound by these Terms and 
Conditions throughout the existence of the relationship. The Terms and Conditions govern the way we do business, 
how we take bids, and how we identify what the Bidders can expect from Shukisha. By using our services, 
regardless of how a Bidder accesses them, the Bidder acknowledges and confirms acceptance of these terms and 
agrees to abide and be bound by them. If a Bidder does not agree to these terms of use, he or she should refrain from 
using our services and if using the service shall be deemed to have agreed. Shukisha Auction shall hereinafter be 
referred to as ‘Shukisha’ and refers to an auction subject to the terms of the Auctioneer’s Act 1996. This agreement 
establishes the Terms and Conditions for the contractual relationship between Shukisha and the Bidders. The Terms 
shall apply to bidding via Mobile Money. You are required to accept these Terms and Conditions when interacting 
with the services offered. Shukisha and You are bound by these Terms and Conditions throughout the existence of 
the relationship. The Company reserves the right to change these Terms and Conditions at any time and without 
prior notice. Best efforts will be made to inform Bidders about major modifications to these Terms and Conditions. 
Notwithstanding the same, it is the responsibility of the Bidder to check for amendments and it shall be assumed that 
by bidding, all Bidders have read, understand, and agree to the Terms and Conditions at the time of entry. Shukisha 
are powered by Arucy Enterprises Limited under Auctioneers licence no 4381 held by Ms. Lonny Wambui 
Waruguru, Auctioneer, trading under the name Rising Sun Auctioneers duly authorized and regulated by the 
Auctioneers Board of Kenya. 
                </p>
                
                <h3>DEFINITIONS</h3>
                <p className="para">
                <h4>AUCTION</h4> 
Typically the lowest unique bid format of reverse auction will be used by Shukisha in order to determine the 
successful bid, but can be defined as any other auction format used by Shukisha at its discretion. 
 

<h4>BID</h4> 
An amount in Kenya Shillings that a Bidder has entered or intends to enter into the Auction. For the avoidance of 
doubt, bids are only accepted in Kenya Shillings as 0.5 and whole positive numbers starting at the lowest KES 1 and 
increasing in increments of KES 0.5 upwards. 

<h4>BID ENTRY FEE </h4> 

the amount in Kenya Shillings (Ksh) deducted via Mpesa that it costs in order to enter the auction, Ksh50 to place 
each Bid, but may be varied by Shukisha at its discretion. 

<h4>BIDDER</h4> 
 
the User of the Shukisha auction that has placed or intends to place a Bid. 

<h4>CURRENT LEADER </h4> 

the Bidder who has placed the lowest Unique Bid at any given time. 

<h4>ITEM</h4> 
 
the product(s) or other worthy inventory that is the focus of the Auction listing. Details of relevant product(s) or 
other inventory are listed on the Site and included in the marketing and media communications. 

<h4>NON-UNIQUE BID</h4> 

 
a Bid that has been placed on a specific Auction by a Bidder where at least one other Bidder has also placed an 
identical Bid on the same Auction. 

<h4>RRP (Recommended Retail Price)</h4> 

 
means the recommended retail price for a specific Item or items within our Auctions. 

<h4>UNIQUE BID</h4>
 
a Bid that has been placed on the Auction by a Bidder where no other Bidder has placed an identical Bid on the 
same Auction. 

<h4>US, WE, OUR, Shukisha</h4>

 
or similar all mean Arucy Enterprises LTD T/A Shukisha. 
THE WEBSITE, OUR WEBSITE, THE SITE or THE WEBSITE 
or similar all mean the website at: www.Shukisha.com 

<h4>SUCCESSFUL BID </h4>


The lowest unique bid in the Auction at the close of the auction. 

<h4>SUCCESSFUL BIDDER </h4>

 
The bidder with the lowest unique bid in the Auction at the close of the auction 
 

<h4>YOU, YOUR, USER</h4>

or similar all mean The Website visitors or Users via Mpesa, or similar, either registered or otherwise. 
                </p>


                <h3>ELIGIBILITY TO PARTICIPATE WITH SHUKISHA</h3>
                <p className="para">
                By using Our Website or entering our Auctions directly via mobile money entry mechanism You 
confirm that: 

<ul>
<li>1. You have the capacity and are thereby legally capable of entering into binding contracts; </li>
  
<li>2. You are at least 18 years old - you may be required to provide proof of Your age at such time as You are the 
Successful Bidder in the Auction on Our Website or at any other time as We deem necessary; </li>
  
<li>3. You are able to make payments to Us using your Mpesa Account; </li>
  
<li>4. You are resident in the Republic of Kenya; </li>
  
<li>5. You are accessing Our Website from within the Republic of Kenya; </li>
  
<li>6. You are not an employee of Shukisha (save in the case of product testing); </li>
  
<li>7. You are participating as an individual only and not as part of a commercial or reseller organisation;</li>

<li>8. You are not a director, shareholder or employee of any company within the Arucy Enterprises Limited group</li>

<li>9. Only one User Account is permitted per person and per telephone number. At the time of a User being the 
Successful Bidder in the Auction, or at any other time that We deem necessary, We may contact the User by 
the registered telephone number affiliated to the mobile money account. We will only dispatch the Item(s) that 
have been purchased via Auction as and when We are satisfied that the registered telephone number belongs 
to the registered User. We may ask that the User confirms certain Account details over the telephone in order 
to confirm that the Account details are correct. </li>

<li>We reserve the right to terminate any User at Our sole discretion. In general, We will only take these actions 
if, in Our reasonable opinion, a User has: 

<ul>
    <li>1. violated any laws or the rights of third parties; </li>
    <li>2. failed to honour payments due to Us;  </li>
    <li>3. used or attempted to use any unauthorised third party Bidding software; </li>
    <li>4. used false or misleading information during registration or amendment of their details; </li>
    <li>collaborated or attempted to collaborate with other Users in an attempt to increase the        likelihood of 
being the Successful Bidder (in this instance collaboration also means a User creating more than one User 
Account); </li>
    <li> created an Account despite being an employee of ours; </li>
    <li>set up or attempted to set up multiple User Accounts.
        <ul>
            <li>
            Where a User Account is terminated due to a breach of these Terms and Conditions, We reserve the right to set 
off any amounts incurred as direct or indirect loss and/or expense by Us investigating the alleged breach prior 
to returning any Balance back to the User and where relevant use any funds held for this purpose. 

            </li>
        </ul>

    </li>
</ul>

</li>
</ul>
                </p>


                <h4>WRITTEN COMMUNICATIONS / NOTICES </h4>

                <p className="para">
                We are required by law in some cases that certain communications from Us to You need to be in written form. 
You agree and accept that in most cases communication from Us to You shall be electronic format, i.e. in the form of 
SMS text message, or in some cases via emails or notices on Our Website. You agree that these electronic means of 
communication comply fully with any legal requirement that such communications be in writing and that any 
contracts, notices and other communications that We provide to You electronically constitute legal written 
communication. 
                    </p>


                    <p className="para">
                    Any notices or communications that You require to be issued to Us must be sent via email to 
info@Shukisha.com and detail Your name, address and registered telephone number. Any communications from our 
partners shall be deemed to be from US.
                    </p>

                    <p className="para">
                    We may issue any notice to You via SMS on the number provided when registering, or email that You provide 
to Us when you contact us as per 3.2. above; and notice will be deemed received and properly served on the day an 
SMS is sent and one day after an email is sent. Further, You agree that in proving the service of any notice, it will be 
sufficient to prove that, in the case of SMS it was dispatched, and, in the case of an email, that such email was sent 
to the email address provided to Us by the User.
                    </p>

                    <p className="para">
                    By signing up to the Shukisha Auction you agree to receive promotional and other messages via SMS and/or via 
our email mailing list so we can contact you from time to time regarding our latest offers, auction reminders and 
general information. This includes but is not limited to, information on auctions you have bid on and Shukisha 
offers. 
                    </p>

                    <p className="para">
                    Shukisha may share your information with our partners of any Auctions that you have bid on. This includes but 
is not limited to Your name & phone number, items You have bid on, etc. 
                    </p>

                    <h4>ENTRY MECHANISM </h4>

                    <p className="para">
                    Bidders may participate by entering their bids via mobile money with the following process upon signing up: 
                    </p>

                    <ul>
                    <li >
                    Click on the items you want to place your bid, once the item page is open, click on the ‘Top Up Now’ green 
button. 
                    </li>
                    <li>
                    There will be a pop-up message which will prompt you to input the amount in Kenya Shillings that you would 
like to top up your account with bid entry fees. You will then click ‘OK’ and another pop up with the message ‘Top 
Up Request Submitted’ will show up. Click on ‘OK’.  
                    </li>

                    <li>
                    A payment prompt will show up on the MPESA phone number that you signed up with on the website. The 
prompt will request you to key in your ‘MPESA PIN NUMBER ONLY’ and the transaction will be completed, with 
the top up money you input deducted from your MPESA account. 
                    </li>


                    </ul>

                    <p className="para">Bidders may participate by entering their bids via the Website with following process: 
                    
                    <ul>
                        <li>
                        Go to Shukisha.com 
                        </li>

                        <li>
                        Select the auction that you wish to bid on and follow the instructions in the fields below

                        <ul>
                            

                            <li>
                            Enter your bid amount (refer to the Definition of a BID above)  
                            </li>

                            <li>
                            When completely sure about the bid amount, click on the ‘Place Bid’ green button on the right. 
                            </li>

                            <li>
                            Await the successful bidding confirmation pop up your screen   
                            </li>
                        </ul>
                        </li>

                        <li>
                        During the auction, bidders receive in-auction alerts and give notification of when the auction is closing. These notifications may include: 

                        <ul>
                            <li>tip on the current auction </li>

                            <li>a ‘last chance to bid’ alert, and </li>

                            <li>a results alert. </li>
                        </ul>
                        </li>

                        <li>
                        The final message that people will receive where applicable is the Successful Bidder message and how much 
they purchased the item for. 
                        </li>

                       
                    </ul>
                    
                    </p>


                    <h4>BIDDING </h4>

                    <ul>
                        <li>
                        To place a Bid, You will only use the shukisha WEBSITE as outlined in the Entry Mechanism above. 
                        </li>
                        <li>
                        We reserve the right to cancel or remove a User’s Bids at Our sole discretion if We believe that the User has 
acted fraudulently or is in breach of these Terms and Conditions. 
                        </li>

                        <li>
                        All Bids are final and no refunds will be issued for Bid Entry Fees paid by You unless the Auction is terminated 
by Us. Your statutory rights are not affected by this term. 
                        </li>
                    </ul>


                    <h4>HOW AUCTIONS WITH Shukisha WORK  </h4>

                    <p className="para">

                    Auctions on Our Website follow a very simple structure and in order to provide You with the knowledge 
required to apply skill and judgement in the Auction, We provide You with the following data for the Auction on 
Our Website: 
<ul>
    <li>
    RRP: this information is provided to the best of Our knowledge where relevant and is usually provided by the 
manufacturer. We cannot guarantee that the RRP is correct but will always use Our best endeavours to ensure that 
the RRP listed is as accurate as possible. 
    </li>

    <li>
    The Bid Entry Fee: this is the amount it will cost You, via Mpesa, to place a Bid for the Item. The Bid Entry Fee 
may vary depending on the Auction, so check this amount prior to placing each Bid to ensure that You are 
comfortable with the Bid Entry Fee, typically Ksh50 per bid. 
    </li>

    <li>
    A Unique Bid is deemed to be a Bid that is the only Bid placed for a specific amount within the Auction. If a 
further Bid is placed by another User for the exact same amount, then both Bids are deemed to be no longer Unique (i.e. they both become Non-Unique Bids).
    </li>

    <li>
    In the event that there is no unique bid, i.e. all bids ranging from the lowest Ksh1 up to the highest bid have two 
or more bids placed, the successful bid shall be the first in time to place the bid that is the lowest with the least 
number of bids placed on it. 
    </li>
    <li>
    Auction close: in the case of a fixed time Auction, the auction closes when the time allocated to the auction 
shall have expired. In these fixed time Auctions, the auction is initially created by Us with a defined amount of time, 
e.g. 24 hours, one week, etc. The type of auction is clearly displayed on the website and in the media and advertising 
for the auction. 
    </li>

    <li>Upon completion of the Auction, the Successful Bidder will receive confirmation of their success by SMS. </li>
</ul>

                    </p>

                    <p className="para">

                    We reserve the right to: 

                    <ul>
                        <li>
                        Suspend or terminate an Auction at any time in the event of technical problems or to investigate suspected 
irregular or fraudulent activity; 
                        </li>

                        <li>
                        Limit the Auctions that You may participate in; Remove an auction if we feel it does not benefit our users and has not had sufficient interest. In this case all 
bids will be recredited to the user’s accounts who placed bids. 
                        </li>
                    </ul>
                        
                    </p>

                    <p className="para">
                    Further information about how the Auctions work in a simpler guide can be found in the FAQ sections of Our 
Website. 
                    </p>

                    <p className="para">

                    For the avoidance of doubt, any advice or help articles appearing on Our Website are intended solely as 
suggestions for techniques and should not be construed as providing a solution to becoming the successful bidder in 
the Auction. We accept no liability whatsoever for any actions taken by a User as a result of reading, or being 
informed about, any of the advice or written materials available on Our Website. 
                        
                    </p>

                    <p className="para">
                    The Successful Bidder will pay Shukisha their bid amount. Shukisha, at its sole discretion may waive the 
requirement to pay by the bidder in lieu of testimonials and other marketing material supplied by the successful 
bidder. 
                    </p>

                    <p className="para">

                    Keywords. The Shukisha auction has a unique keyword, typically at any given time, Shukisha accepts no 
liability for any mistakes made by bidders who do not follow the clear instructions provided herein and on the 
website and enter incorrect information into the account field when bidding. 
                        
                    </p>

                    <p className="para">
                    Bid Increments: Bids are accepted in full shillings and in 50 Cents(KES 0.50)  
                    </p>

                    <p className="para">

                    In the event that a bidder refuses to or cannot accept the item that is offered in the auction, that bidder forfeits 
the auction item. In some circumstances, the next lowest unique bid may be deemed the successful bid and that 
bidder may be deemed to be and announced as the successful bidder, however this is solely at the discretion of 
Shukisha who may decide on an alternative course of action in such circumstances. 
                        
                    </p>

                    <h4>CONFIRMATION OF THE SUCCESSFUL BIDDER DETAILS   </h4>

                    <p className="para">It is a condition precedent to formation of a Contract between the Successful Bidder and Us that the telephone 
number held by Us at the time of being announced the successful bidder in the Auction is valid and that the User can 
be reached by calling or text it via SMS. In the event that the telephone number is not correct, the Contract cannot be 
formed and the successful bid will be forfeited. </p>

<p className="para">
Upon completion of the Auction, the Successful Bidder will be required to confirm their registered telephone 
number by receiving a text from Us. We will attempt to call or text the Successful Bidder’s registered telephone 
number within two (2) working days of the Auction ending and may request that the User confirms some details 
from their Account in order to verify the User. In the event that the telephone number on file has voicemail, We will 
leave a message requesting that the User returns Our call to verify their Account. Alternatively, the Successful 
Bidder may call or email Us to request a call back. In any event, the only acceptable methods of verification is for 
Us to call and/or text You on the telephone number that You have provided as part of Your User Account details. 
</p>

<p className="para">
The Successful Bidder will have three (3) days from the close of the auction to confirm to Shukisha their 
information, if he or she does not confirm within this time period, Shukisha shall at their discretion presume the 
User does not want the item and it will be forfeited by the User. 
</p>

<h4>RETURNS AND CANCELLATIONS / CONSUMER RIGHTS </h4>

<p className="para">
Where We are unable to provide the Auction Item, We reserve the right to issue a full refund of the purchase 
price or RRP, which will constitute full consideration for the Item. 
</p>

<p className="para">Your statutory rights are not affected by this section of these Terms and Conditions. </p>


<h4>SETTING OFF / WITHHOLDING PAYMENT / RETENTION OF TITLE </h4>

<p className="para">
In the case of an outstanding payment of any kind and / or chargeback against the User, any Items delivered to the 
User will remain the property of Shukisha until such time as the amounts owed to Us are paid in full. During this 
time, You undertake to store the Item separately from Your other possessions and not take beneficial use of the Item 
until such time as the payment has been received and cleared in full. 
</p>

<h4>DISCLAIMER OF WARRANTY AND LIMITATION OF LIABILITY REGARDING THE USE OF 
Shukisha </h4>

<p className="para">
The User expressly agrees that the use of the Shukisha auction is at User's sole risk. Neither Shukisha, its 
affiliates nor any of their respective employees, agents, third party content providers or licensors warrant that the 
Shukisha auction will be uninterrupted or error free; nor do they make any warranty as to the results that may be 
obtained from the use of the Shukisha auction, or as to the accuracy, reliability or content of any information, 
service, or merchandise provided through Shukisha.
</p>

<p className="para">
The Shukisha auction is provided on an "as is" basis; to the maximum extent permitted by law, Shukisha 
disclaims all representations and warranties, expressed or implied, with respect to any information, services, 
products and materials, including, but not limited to, warranties of merchantability or fitness for a particular 
purpose, title, non-infringement, freedom from computer virus and implied warranties arising from course of dealing 
or course of performance. In addition, Shukisha does not represent or warrant that the information accessible via the 
site is accurate, complete or current. We are not responsible for typographical errors. 
</p>

<p className="para">
This disclaimer of liability applies to any damages or injury caused by any failure of performance, error, 
omission, interruption, deletion, defect, delay in operation or transmission, computer virus, communication line 
failure, theft or destruction or unauthorised access to, alteration of, or use of record, whether for breach of Contract, 
tortious behavior, negligence, or under any other cause of action. The User specifically acknowledges that Shukisha 
is not liable for the defamatory, offensive or illegal conduct of other Users or third-parties and that the risk of injury 
from the foregoing rests entirely with the User.
</p>

<p className="para">
In no event will Shukisha, or any person or entity involved in the use of the Shukisha Auction, be liable for 
any damages, including, without limitation, direct, indirect, incidental, special, consequential or punitive damages 
arising out of the use of or inability to use the Shukisha auction. The User hereby acknowledges that the provisions 
of this section shall apply to all content on The Website. 
</p>

<p className="para">
In addition to the Terms set forth above neither, Shukisha, nor its affiliates, information providers or content 
partners shall be liable regardless of the cause or duration, for any errors, inaccuracies, omissions, or other defects 
in, or untimeliness or unauthenticity of, the information contained on Our Website, or for any delay or interruption 
in the transmission thereof to the User, or for any claims or losses arising therefrom or occasioned thereby. None of 
the foregoing parties shall be liable for any third-party claims or losses of any nature, including, but not limited to, 
lost profits, punitive or consequential damages. Neither, Shukisha, nor its affiliates, information providers or content 
partners warrant or guarantee the timeliness, sequence, accuracy or completeness of this information. Additionally, 
there are no warranties as to the results obtained from the use of the information. 
</p>


<p className="para"> With regards to the Auction process and / or other elements of Our Website, We will not be liable for; any 
losses arising from Your negligence; any failure to follow Our instructions as to placing Bids; any use of websites 
linked to Our Website but not operated by Us; any loss or damage due to viruses or other malicious software that 
may infect Your computer equipment, software, data or other property caused by You accessing, using or downloading from Our Website, or from transmissions via emails or attachments received from Us; faulty 
operations of computers during Bid placement and / or for incorrect or overly slow transmission of Bidding data by 
the internet provider and / or any damage that occurs due to Bids submitted by You not being received by Us or not 
being received promptly or not being considered, as a consequence of technical faults with Our software or 
hardware (whether same are within Our control or otherwise). </p>

<p className="para">We accept no liability for any indirect losses howsoever caused including without limitation; loss of income or 
revenue; loss of business; loss of profits or future contracts; loss of anticipated savings; loss of data; or waste of 
management or office time or space whether caused by tort, negligence, breach of Contract or otherwise, even if 
foreseeable. </p>

<p className="para">
For the avoidance of doubt, the above provisions do not in any way limit Our liability; for death or personal 
injury caused by Our negligence; under section 2(3) of the Consumer Protection Act 1987; for fraud or fraudulent 
misrepresentation; or for any matter for which it would be illegal for Us to exclude, or attempt to exclude, Our 
liability. 
</p>

<h4>DISCLAIMER OF WARRANTY AND LIMITATION OF LIABILITY REGARDING ITEMS PURCHASED 
FROM Shukisha </h4>

<p className="para">
We warrant to You that the Auction Item purchased from Us through Our Website is of satisfactory quality and 
reasonably fit for all of the purposes for which the Item of that kind are commonly supplied. 
</p>

<p className="para">
The Auction Item is sold with the manufacturer’s limited warranty only, where applicable. 
</p>

<p className="para">
With the exception of the warranties set forth above, Shukisha makes no other warranties, either expressed or 
implied, with respect to the auction Item, or any related services performed by Shukisha or any of its agents or 
subcontractors in connection with any order, including, without limitation, any warranties of fitness for a particular 
purpose, which other warranties are expressly excluded and disclaimed. 
</p>

<p className="para">Shukisha shall not be liable for personal injury and property damage resulting from the improper handling, 
modification, misuse of the auction Item by the User or any other person following delivery by Shukisha. In no 
event shall Shukisha be liable to any person for incidental or consequential damages including but not limited to loss 
of profits or goodwill, loss-of-use damages or additional expenses incurred, whether pursuant to a claim in Contract, 
tort or otherwise and whether in an action for breach of warranty or otherwise.</p>

<p className="para">We accept no liability for any indirect losses howsoever caused including without limitation; loss of income or 
revenue; loss of business; loss of profits or future contracts; loss of anticipated savings; loss of data; or waste of 
management or office time or space whether caused by tort, negligence, breach of Contract or otherwise, even if 
foreseeable. </p>

<p className="para">For the avoidance of doubt, the above provisions do not in any way limit Our liability; for death or personal 
injury caused by Our negligence; under section 2(3) of the Consumer Protection Act 1987; for fraud or fraudulent 
misrepresentation; or for any matter for which it would be illegal for Us to exclude, or attempt to exclude, Our 
liability. </p>


<h4>SYSTEM OUTAGES / SERVICE AVAILABILITY / TEMPORARILY SUSPENDED AUCTIONS</h4>

<p className="para">
We endeavour to provide an efficient and error-free platform in Our Website for You to participate in the 
Bidding process. However, it is not possible to develop and operate computer programs (software) and data 
processing systems (hardware) completely without errors, or to rule out any unpredictable events in connection with 
the internet (hereinafter referred to collectively as “technical faults”). We therefore provide no guarantee or warranty 
for the constant and uninterrupted availability of Our Website and other systems. In addition We accept no liability 
for any damage that occurs due to Bids submitted by Users not being received by Us or not being received promptly 
or not being considered, as a consequence of technical faults. 
</p>

<p className="para">Auctions may be temporarily suspended in the event of technical faults until such time as the issue has been 
resolved, such events are called ‘System Outages’. In the event that this occurs We will use Our best endeavors to 
return to normal service as quickly as possible; during the period of the technical fault, all Auctions may be 
suspended and will resume upon rectification of the fault. Any Bid Prices paid for Bids placed by a User that are not considered as a result of a technical fault will not be refunded. </p>

<p className="para">Our Website is intended solely for use by residents of the Republic of Kenya and We do not accept orders from 
persons outside of this jurisdiction. Deliveries will only be made to the address provided. </p>

<p className="para">Access to Our Website is granted on a temporary basis and We reserve the right to restrict or suspend access to 
The Website or close it indefinitely at any time We deem necessary without prior notice. You are personally 
responsible for making all arrangements necessary for You to gain access to Our Website. </p>


<h4>TRADEMARKS</h4>

<p className="para">
In relation to the Shukisha logo text, the website Shukisha.com, the Shukisha general character, are intellectual 
property of Shukisha and its partners, All Rights Reserved. The auctions platform and format are powered by and 
specifically remain the property of our partner. 
    
</p>

<h4>APPLICABLE LAW / SEVERABILITY </h4>

<p className="para">
Any dispute or claim arising out of or in connection with these Terms and Conditions or Items purchased 
(including non-contractual disputes or claims) will be governed solely by Kenyan law. Any dispute or claim arising 
out of or in connection with Contracts or their formation (including non-contractual disputes or claims) will be 
subject to the non-exclusive jurisdiction of the courts of the Republic of Kenya. 
</p>

<p className="para">
We aim to limit the use of Our Website and services for people outside of the Republic of Kenya. If You access 
Our Website from any other jurisdiction You do so of Your own volition. 
</p>

<p className="para">If any of these Terms and Conditions or any provisions of a Contract are determined by any competent 
authority to be invalid, unlawful or unenforceable to any extent, such term, condition or provision will to that extent 
be severed from the remaining Terms, Conditions and provisions which will continue to be valid to the fullest extent 
permitted by law. </p>


<h4>PROTECTION OF DATA: COLLECTION, PROCESSING AND USE OF YOUR PERSONAL 
INFORMATION </h4>

<p className="para">We treat the protection of Your personal data with extreme care and will never disclose Your data to third 
parties for marketing or any other purposes, except in the case of our affiliates. </p>

<p className="para">By participating in sponsored auctions you agree to allow our sponsor to email/text/Whatsapp you further 
details of the product in the auction, during, before and after its running. </p>

<p className="para">Shukisha acknowledges adherence to the Data Protection Act and other relevant Kenyan legislation and 
regulation in relation to data privacy. </p>

<h4>EXTERNAL LINKS </h4>

<p className="para">
Our Website may contain links to other websites operated by third parties, and these sites may likewise contain links 
to other websites. Despite conducting careful checks, We assume no liability for the content of external links. The 
operators of the sites linked to and from this site are solely responsible for their contents. We hereby expressly 
distance ourselves from all content of any and all websites linked to Our Website. We cannot take any responsibility 
for the content of the data protection and privacy guidelines of third-party websites. 
</p>

<h4>TRANSFER OF RIGHTS AND OBLIGATIONS </h4>

<p className="para">The Contract between You and Us is binding on You and Us and on Our respective successors and assigns. You may 
not transfer, assign, charge or otherwise dispose of a Contract, or any of Your rights or obligations arising under it, 
without Our prior written consent. We may transfer, assign, charge, sub-Contract or otherwise dispose of a Contract, 
or any of Our rights or obligations arising under it, at any time during the term of the Contract. We are entitled to 
involve third parties to satisfy Our contractual obligations without being required to notify You. </p>


<h4>OUR RIGHT TO VARY THESE TERMS AND CONDITIONS  </h4>

<p className="para">
We may at any time and at Our sole discretion revise these Terms and Conditions to reflect inter alia, changes 
in market Conditions affecting Our business, changes in technology, changes in payment methods, changes in 
relevant laws and regulatory requirements and changes in Our system’s capabilities. You will be subject to the 
policies and Terms and Conditions in force at the latest time that You accept them, unless any change is required to 
be made by law or governmental authority (in which case it will apply to orders previously placed by You), or if We 
notify You of the change to those policies or these Terms and Conditions (in which case We have the right to assume 
that You have accepted the change to the Terms and Conditions unless You notify Us to the contrary within seven 
days of receipt by You of the notification of the changes). 

</p>

<p className="para">
You may be required to agree to additional Terms and Conditions from time to time. Unless otherwise 
expressly provided by the additional Terms and Conditions, those additional Terms are hereby incorporated into 
these Terms and Conditions. 
    
</p>

<h4>VIRUSES, HACKING AND OTHER OFFENCES </h4>

<p className="para">
You are expressly prohibited from misusing Our Website in any way. In these Conditions misuse is defined as; 
introducing viruses, Trojans, worms, logic bombs or other material which is malicious or technologically harmful; 
gaining or attempting to gain access to Our Website, the server on which Our Website is stored or any server, 
computer or database connected to Our Website; attacking or attempting to attack Our Website via any denial of 
service attack or distributed denial of service attack; or, attacking or attempting to attack Our Website by any other 
means that is recognised as an attack vector. 
</p>


<h4>VIRUSES, HACKING AND OTHER OFFENCES </h4>

<p className="para">
You are expressly prohibited from misusing Our Website in any way. In these Conditions misuse is defined as; 
introducing viruses, Trojans, worms, logic bombs or other material which is malicious or technologically harmful; 
gaining or attempting to gain access to Our Website, the server on which Our Website is stored or any server, 
computer or database connected to Our Website; attacking or attempting to attack Our Website via any denial of 
service attack or distributed denial of service attack; or, attacking or attempting to attack Our Website by any other 
means that is recognised as an attack vector. 
</p>

<h4>FORCE MAJEURE AND OTHER EVENTS OUTSIDE OF Shukisha’S CONTROL </h4>

<p className="para">
We are not liable or responsible for any failure to perform, or delay in performance of, any of Our obligations under 
a Contract that is caused by any act, event, non-happening, omission or accident beyond Our reasonable control. 
Such events are called ‘Force Majeure Events’ and include particularly, without limitation; strikes, lock-outs or other 
industrial action; civil commotion, riot, invasion, terrorist attack or threat of terrorist attack, war (whether declared 
or not) or threat or preparation for war, fire, explosion, implosion, storm, flood, earthquake, subsidence, epidemic or 
other natural disaster; impossibility of the use of railways, shipping, aircraft, motor transport or other means of 
public or private transport; impossibility of the use of public or private telecommunications networks; and, the acts, 
decrees, legislation, regulations or restrictions of any government. 
</p>

<p className="para">
Our performance under any Contract is deemed to be suspended for the period that the force majeure event 
continues, and We will have an extension of time for performance for the duration of that period. We will use Our 
reasonable endeavours to bring the force majeure event to a close or to find a reasonable alternative solution by 
which We are able to perform Our obligation under the Contract despite the force majeure event. 
</p>

<h4>TERM OF PROVISIONS </h4>


<p className="para">

These Terms and Conditions remain in full force and effect from acceptance until terminated by either party on 
30 days’ written notice or unless terminated in accordance with these Terms and Conditions. 
</p>

<p className="para">

These Terms and Conditions take precedence over any information displayed on Our Website or any other 
publication at all times. 

</p>


<h4>BUSINESS REGISTRATION DETAILS  </h4>

<p className="para">
Shukisha is wholly owned by Arucy Enterprises Limited, and the website running the auction and all of its sub-
domains is a Website owned and operated by Us and powered by our partners. 
</p>


<h4>GENERAL PROVISIONS  </h4>

<p className="para">
We reserve the right to act upon Our sole discretion in implementing the Terms and Conditions. Further, We 
reserve the right to take action against anyone who, in Our opinion, is in breach of these Terms and Conditions. Such 
action includes, without limitation, terminating Your registration and terminating access to Our Site and Auctions on 
Our Site and via mobile money.
</p>

<p className="para">
Our partners and Us are the owner or licensee of all intellectual property rights on Our Website and material published on it. All such material is protected by copyright laws and treaties around the world and all rights relating 
to the same are reserved. You may not modify or reproduce any digital or paper copies of any material either printed 
or downloaded from Our Website nor use any illustrations, photographs, video or audio sequences or any graphics 
separately from any accompanying text. Further You may not use any of the materials on Our Website for 
commercial purposes. 
</p>

<p className="para">
We always use Our best endeavours to ensure that all descriptions on Our Website are as accurate as possible. 
However, We may sometimes not succeed and the Items offered may differ from those described on Our Website 
due to, without limitation; later production changes in specification, components or place of manufacture; 
inaccuracies on Our Website; or typographical errors, generally. 
</p>

<p className="para">
These Terms and Conditions and any document expressly referred to in them represent the entire agreement 
between Us and You in relation to the subject matter of any Contract and supersede any prior agreement, 
understanding or arrangement between Us and You, whether oral or in writing. 
</p>

<p className="para">
We each acknowledge that, in entering into a Contract, neither of Us has relied on any representation, 
undertaking or promise given by the other or to be implied from anything said or written in negotiations between Us 
and You prior to such Contract except as expressly stated in these Terms and Conditions. 
</p>

<p className="para">
In the event that you are the successful bidder, You will automatically be deemed to grant to Shukisha an 
irrevocable right to publish, through all types of media broadcasting, including the internet, for the purposes of 
promoting the auction, Your full name (as well as Your nick name), hometown, photograph, and video materials 
without any claim for broadcasting, printing or other rights, for a period of up to thirty-six months from the date of 
the auction. You also give Shukisha the right to publish, for a period of up to thirty-six months from the date of the 
auction, any additional information that You voluntarily provide, and any testimonial or other commentary relating 
to the auction or the product, attributed by consent to you. You shall have no claim against Shukisha for invasion of 
privacy or on any other ground(s) by reason of the publication or broadcasting of Your Name, hometown, 
photograph, video materials, or information voluntarily provided by You. 
</p>

<p className="para">
Neither You nor Us will have any remedy in respect of any untrue statement made by the other, whether orally 
or in writing, prior to the date of any Contract (unless such untrue statement was made fraudulently) and the other 
party´s only remedy will be for breach of Contract as provided in these Terms and Conditions. 
</p>

<p className="para">
A waiver by Us of any breach or default hereunder shall not be deemed to be a waiver of any preceding or 
subsequent breach or default. Further, failure by Us to exercise any of the rights or remedies to which We are 
entitled under the Contract will not constitute a waiver of such rights or remedies and will not relieve You from 
compliance or performance of such obligations. No waiver by Us of any of these Conditions will be effective unless 
it is expressly stated to be a waiver and is communicated to You by Us in writing. 
</p>

<p className="para">
All section headings within these Conditions serve solely to assist in reading and for convenience and shall not 
be given any legal import. 
</p>

<p className="para">
All bids entry fees re subject to and inclusive of VAT at the prevailing rate, together with any other applicable 
taxes that may arise from time to time. 
</p>

                    

              </div>
            </div>
          </div>
        </div>
      </div>   
    </>
  )
}

export default WhoWeAreArea