import React from 'react'
import { Link } from 'react-router-dom'

function WhoWeAreArea() {
  return (
    <>
      <div className="about-section pt-120 pb-120 overflow-hidden">
        <img src={process.env.PUBLIC_URL + "/images/bg/section-bg.png"} className="img-fluid section-bg-top" alt="section-bg" />
        <div className="container">
          <div className="row d-flex justify-content-center g-4">
            <div className="col-lg-6 col-md-10">
              <div className="about-img-area">
                
                <img src={process.env.PUBLIC_URL + "/images/bg/about-img.png"} className="img-fluid about-img wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".2s" alt="about-img" />
                <img src={process.env.PUBLIC_URL + "/images/bg/about-linear.png"} className="img-fluid about-linear" alt="" />
                <img src={process.env.PUBLIC_URL + "/images/bg/about-vector.png"} className="img-fluid about-vector" alt="" />
              </div>
            </div>
            <div className="col-lg-6 col-md-10">
              <div className="about-content wow fadeInDown" data-wow-duration="1.5s" data-wow-delay=".2s">
                <span>We are Shukisha!</span>
                <h2>We Work to Bring You the Latest and Greatest in Consumer Tech</h2>
                <p className="para">Auction sites present consumers with a thrilling, competitive way to buy the goods and services they need most. Here at Shukisha, We strive to give you the best experience possible no matter what device you are on.</p>
                <p className="para">By joining our service you will have a chance to compete and acquire some of the most exclusive consumer items you can find at eye-watering discounts. How much you save is all about how creating you can be with your timing and amounts.</p>

                <p className="para">Shukisha in Swahili means to 'bring down' or 'reduce' something. We purpose to live up to our name by ensuring that you get the best that the tech world has to offer without breaking a sweat. Welcome and we hope you have a great time!</p>
                
              </div>
            </div>
          </div>
        </div>
      </div>   
    </>
  )
}

export default WhoWeAreArea