import React from 'react'

import WhatsAppWidget from "react-whatsapp-chat-widget";









import AboutUsCounter from '../../common/AboutUsCounter'
import Breadcrumb from '../../common/Breadcrumb'
import MakeMoneyContent from './MakeMoneyContent'







function MakeMoney() {
  return (
    <>
     <Breadcrumb pageName="Make Money" pageTitle="How To Make Money With Shukisha"/>  
     <MakeMoneyContent/>
     <WhatsAppWidget
			phoneNo="254205002033"
			position="right"
			widgetWidth="300px"
			widgetWidthMobile="260px"
			autoOpen={false}
			autoOpenTimer={5000}
			messageBox={true}
			messageBoxTxt="Hi"
			iconSize="40"
			iconColor="white"
			iconBgColor="green"
			headerIcon="https://www.shukisha.com/images/bg/shukisha-logo-v2.svg"
			headerIconColor="pink"
			headerTxtColor="black"
			headerBgColor="#32c36c"
			headerTitle="Shukisha Support"
			headerCaption="Online"
			bodyBgColor="#bbb"
			chatPersonName="Support"
			chatMessage={<>Hi there 👋 <br /><br /> Welcome to Shukisha. How can we be of assistance?</>}
			footerBgColor="white"
			btnBgColor="#d63384"
			btnTxtColor="black"
			btnTxt="Start Chat"
		/>
    </>
  )
}

export default MakeMoney