import React from 'react'
import { Link , useHistory} from 'react-router-dom';

import {isUserLoggedIn} from '../../../common/data_methods'
import Breadcrumb from '../../common/Breadcrumb'
import SignUpWrap from './SignUpWrap'


function SignUp() {

  const history = useHistory()

  if(isUserLoggedIn()){
    history.push('/dashboard');
  } 
  
  return (
    <>
       
     <SignUpWrap/>
    </>
  )
}

export default SignUp