import React from 'react'
import Breadcrumb from '../../common/Breadcrumb'
import { Link , useHistory} from 'react-router-dom';
import LoginWrap from './LoginWrap'
import {isUserLoggedIn} from '../../../common/data_methods'

function Login() {

  const history = useHistory()

  if(isUserLoggedIn()){
    history.push('/dashboard');
  } 

  return (
    <>
     <Breadcrumb pageName="Log In" pageTitle="Log In" />  
     <LoginWrap/> 
    </>
  )
}

export default Login