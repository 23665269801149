import React from "react";
import { Link } from "react-router-dom";


function WelcomeContent() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  return (
    <>
      <div className="how-work-section pt-120 pb-120">
        <img
          alt="images"
          src={process.env.PUBLIC_URL + "/images/bg/section-bg.png"}
          class="section-bg-top"
        />
        <div className="container">

          <div className="row g-4 mb-60">
            
            
            <div className="col-xl-6 col-lg-6">
              <div
                className="how-work-content wow fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay=".2s"
              >
                <span>01.</span>
                <h3>Check out how to win</h3>
                <p className="para">
                  Before you start bidding, you need to understand the rules of the game. We have a page that explains how to win on Shukisha. Read through and you will be ready to start bidding.
                </p>

                <Link
                  to={`${process.env.PUBLIC_URL}/how-to-win`}
                  onClick={scrollTop}
                  className="eg-btn btn--primary btn--md"
                >
                  Learn How to WIn
                </Link>
                 
              </div>
            </div>

            <div className="col-xl-6 col-lg-6">
              <div
                className="how-work-content wow fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay=".2s"
              >
                <span>02.</span>
                <h3>Read our Terms</h3>
                <p className="para">
                  Before you get started, you need to read our terms and conditions. This is a simple process and only takes a few minutes.
                </p>

                <Link
                  to={`${process.env.PUBLIC_URL}/terms`}
                  onClick={scrollTop}
                  className="eg-btn btn--primary btn--md"
                >
                  Read Terms
                </Link>
                 
              </div>
            </div>

            <div className="col-xl-6 col-lg-6">
              <div
                className="how-work-content wow fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay=".2s"
              >
                <span>03.</span>
                <h3>Activate Your Affiliate Account</h3>
                <p className="para">
                  Once you have registered, you need to go to the 'My Account' section and find the 'Affiliates' tab. Here you can activate your affiliate account. Once you have activated your account you can start making money.
                  You will get a referral link that you can share with your friends and family.
                </p>
                
                
              </div>

            </div>

            <div className="col-xl-6 col-lg-6">
              <div
                className="how-work-content wow fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay=".2s"
              >
                <span>04.</span>
                <h3>Start Bidding</h3>
                <p className="para">
                  You are now ready to start bidding. You can bid on any item that you like and as many times as you like. You can also share your referral link with your friends and family and earn money.
                </p>

                <Link
                  to={`${process.env.PUBLIC_URL}/`}
                  onClick={scrollTop}
                  className="eg-btn btn--primary btn--md"
                >
                  Start bidding
                </Link>
                
                
              </div>
            </div>
            
            
          </div>

          
          


        </div>
      </div>
    </>
  );
}

export default WelcomeContent;
