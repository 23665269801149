import React, { useState } from 'react'

function ContentOfProfile(props) {
  const [openEye, setOpenEye]= useState(1);

  const [firstName, setFirstName]= useState(props.user_profile.first_name);
  const [lastName, setLastName]= useState(props.user_profile.last_name);
  const [email, setEmail]= useState(props.user_profile.email);
  const [address, setAddress]= useState(props.user_profile.address);

  const handleEyeIcon = ()=>{
    if(openEye === false || openEye ===0){
      setOpenEye(1)
    }else{
      setOpenEye(false)
    }
  }
  return (
    <>
        <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
        <div className="dashboard-profile">
          <div className="owner">
            <div className="image">
              <img alt="images" src={ process.env.PUBLIC_URL +"/images/bg/generic-avatar.png"}  height={120}/>
            </div>
            <div className="content">
              <h3>{props.user_profile.first_name} {props.user_profile.last_name}</h3>
              <p className="para">{props.user_profile.username}</p>
            </div>
          </div>
          <div className="form-wrapper">
            <form action="#" onSubmit={props.handleSubmit}>
              <div className="row">
                <div className="col-xl-6 col-lg-12 col-md-6">
                  <div className="form-inner">
                    <label>First Name *</label>
                    <input type="text" placeholder="Your first name" name="first_name" value={firstName} onChange={(e) => {setFirstName(e.target.value)}}/>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-12 col-md-6">
                  <div className="form-inner">
                    <label>Last Name *</label>
                    <input type="text" placeholder="Your last name" name="last_name" value={lastName} onChange={(e) => {setLastName(e.target.value)}}/>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-12 col-md-6">
                  <div className="form-inner">
                    <label>Contact Number</label>
                    <input type="text" placeholder={+254} name="phone" disabled value={props.user_profile.phone}/>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-12 col-md-6">
                  <div className="form-inner">
                    <label>Email</label>
                    <input type="text" placeholder="Your Email" name="email" value={email} onChange={(e) => {setEmail(e.target.value)}}/>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-inner">
                    <label>Address</label>
                    <input type="text" name="address" value={address} onChange={(e) => {setAddress(e.target.value)}}/>
                  </div>
                </div>
                
                <div className="col-12">
                  <div className="form-inner">
                    <label>Password *</label>
                    <input type={openEye === 1 ? "password": "text"} name="password" id="password" placeholder="Current Password" required/>
                    <i className={openEye === 1 ? "bi bi-eye-slash" :"bi bi-eye-slash bi-eye"} id="togglePassword" onClick={handleEyeIcon} />
                  </div>
                </div>
                
                <div className="col-12">
                  <div className="button-group">
                    <button type="submit" className="eg-btn profile-btn">Update Profile</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  ) 
}
export default ContentOfProfile