import React from "react";
import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";




import AboutUsCounter from "../../common/AboutUsCounter";
import CategoryHome2 from "./CategoryHome2";
import HeroBanner from "./HeroBanner";
import LiveAuctionArea from "./LiveAuctionArea";

 

function HomePageTwo(props) {

  return (
    <>
    <HeroBanner/>
    <LiveAuctionArea id="lva" activeAuctions={props.activeAuctions} pastAuctions={props.pastAuctions}/>
    <WhatsAppWidget
			phoneNo="254205002033"
			position="right"
			widgetWidth="300px"
			widgetWidthMobile="260px"
			autoOpen={false}
			autoOpenTimer={5000}
			messageBox={true}
			messageBoxTxt="Hi"
			iconSize="40"
			iconColor="white"
			iconBgColor="green"
			headerIcon="https://www.shukisha.com/images/bg/shukisha-logo-v2.svg"
			headerIconColor="pink"
			headerTxtColor="black"
			headerBgColor="#32c36c"
			headerTitle="Shukisha Support"
			headerCaption="Online"
			bodyBgColor="#bbb"
			chatPersonName="Support"
			chatMessage={<>Hi there 👋 <br /><br /> Welcome to Shukisha. How can we be of assistance?</>}
			footerBgColor="white"
			btnBgColor="#d63384"
			btnTxtColor="black"
			btnTxt="Start Chat"
		/>
    </>
  );
}

export default HomePageTwo;
