import React from "react";
import { Link } from "react-router-dom";

function HowItWorkContent() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  return (
    <>
      <div className="how-work-section pt-120 pb-120">
        <img
          alt="images"
          src={process.env.PUBLIC_URL + "/images/bg/section-bg.png"}
          class="section-bg-top"
        />
        <div className="container">
          <div className="row g-4 mb-60">
            <div className="col-xl-6 col-lg-6">
              <div
                className="how-work-content wow fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay=".2s"
              >
                <span>01.</span>
                <h3>Register Now, it's so easy and fast</h3>
                <p className="para">
                  Registering is so easy and fast. We won't ask you too many questions, just your phone number and Screen Name and you'll be ready to go. You'll get your login information immediately and you can start using Shukisha right away.
                </p>
                
                <Link
                  to={`${process.env.PUBLIC_URL}/signup`}
                  onClick={scrollTop}
                  className="eg-btn btn--primary btn--md"
                >
                  Register Account
                </Link>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 d-flex justify-content-lg-end justify-content-center">
              <div
                className="how-work-img wow fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay=".2s"
              >
                <img
                  alt="images"
                  src={process.env.PUBLIC_URL + "/images/bg/how-work1.png"}
                  className="work-img"
                />
              </div>
            </div>
          </div>
          <div className="row g-4 mb-60">
            <div className="col-xl-6 col-lg-6 d-flex justify-content-lg-start justify-content-center order-lg-1 order-2">
              <div
                className="how-work-img wow fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay=".2s"
              >
                <img
                  alt="images"
                  src={process.env.PUBLIC_URL + "/images/bg/how-work2.png"}
                  className="work-img"
                />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 order-lg-2 order-1">
              <div
                className="how-work-content wow fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay=".2s"
              >
                <span>02.</span>
                <h3>Select Your Item</h3>
                <p className="para">
                  Did you see something you like? Maybe a new phone, a new laptop, a new TV, or a new car? You can get it all on Shukisha. Just select the item you want and get to bidding. We won't stand in the way of getting what you want.
                </p>
                
                <Link
                  to={`${process.env.PUBLIC_URL}`}
                  onClick={scrollTop}
                  className="eg-btn btn--primary btn--md"
                >
                  Add Your Item
                </Link>
              </div>
            </div>
          </div>
          <div className="row g-4">
            <div className="col-xl-6 col-lg-6">
              <div
                className="how-work-content wow fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay=".2s"
              >
                <span>03.</span>
                <h3>Purchase Items</h3>
                <p className="para">
                  Our account topup process is so seamless you'll barely notice it. We support mobile money payments for now but we'll be adding more payment methods soon. You can also use your Shukisha account balance to purchase items, especially if you are about to win that iPhone before the timer runs out.
                </p>
                <Link
                  to={`${process.env.PUBLIC_URL}`}
                  onClick={scrollTop}
                  className="eg-btn btn--primary btn--md"
                >
                  Purchase Item
                </Link>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 d-flex justify-content-lg-end justify-content-center">
              <div
                className="how-work-img wow fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay=".2s"
              >
                <img
                  alt="images"
                  src={process.env.PUBLIC_URL + "/images/bg/how-work3.png"}
                  className="work-img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HowItWorkContent;
