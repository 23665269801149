import React from 'react'

import WhatsAppWidget from "react-whatsapp-chat-widget";




function ContactWrapper() {
  return (
    <>
    <div className="contact-section pt-120 pb-120">
        <img alt="images" src={process.env.PUBLIC_URL + '/images/bg/section-bg.png'} className="img-fluid section-bg-top" />
        <img alt="images" src={process.env.PUBLIC_URL + '/images/bg/section-bg.png'} className="img-fluid section-bg-bottom" />
        <div className="container">
          <div className="row pb-120 mb-70 g-4 d-flex justify-content-left">
            
            <div className="col-lg-4 col-md-6 col-sm-8">
              <div className="contact-signle hover-border1 d-flex flex-row align-items-center wow fadeInDown" data-wow-duration="1.5s" data-wow-delay=".4s">
                <div className="icon">
                  <i className="bx bx-phone-call" />
                </div>
                <div className="text">
                  <h4>Phone</h4>
                  <a href="tel:+254205002033">+254205002033</a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-8">
              <div className="contact-signle hover-border1 d-flex flex-row align-items-center wow fadeInDown" data-wow-duration="1.5s" data-wow-delay=".6s">
                <div className="icon">
                  <i className="bx bx-envelope" />
                </div>
                <div className="text">
                  <h4>Email</h4>
                  <a href="mailto:info@shukisha.com">info@shukisha.com</a>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-4">
            <div className="col-lg-12">
              <div className="form-wrapper wow fadeInDown" data-wow-duration="1.5s" data-wow-delay=".2s">
                <div className="form-title2">
                  <h3>Get in Touch</h3>
                  <p className="para">Feel free to ask me any question or let's talk about possible collaborations. Click on the whatsapp widget on the bottom right corner.</p>
                </div>
                
              </div>
            </div>

            <WhatsAppWidget
			phoneNo="254205002033"
			position="right"
			widgetWidth="300px"
			widgetWidthMobile="260px"
			autoOpen={false}
			autoOpenTimer={5000}
			messageBox={true}
			messageBoxTxt="Hi"
			iconSize="40"
			iconColor="white"
			iconBgColor="green"
			headerIcon="https://www.shukisha.com/images/bg/shukisha-logo-v2.svg"
			headerIconColor="pink"
			headerTxtColor="black"
			headerBgColor="#32c36c"
			headerTitle="Shukisha Support"
			headerCaption="Online"
			bodyBgColor="#bbb"
			chatPersonName="Support"
			chatMessage={<>Hi there 👋 <br /><br /> Welcome to Shukisha. How can we be of assistance?</>}
			footerBgColor="white"
			btnBgColor="#d63384"
			btnTxtColor="black"
			btnTxt="Start Chat"
		/>

            
           
          </div>
        </div>
      </div>
    </>
  )
}

export default ContactWrapper