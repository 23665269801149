import React from "react";
import { Link } from "react-router-dom";
























function HowToWInContent() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  return (
    <>
      <div className="how-work-section pt-120 pb-120">
        <img
          alt="images"
          src={process.env.PUBLIC_URL + "/images/bg/section-bg.png"}
          class="section-bg-top"
        />
        <div className="container">

          <div className="row g-4 mb-60">
            
            
            <div className="col-xl-6 col-lg-6">
              <div
                className="how-work-content wow fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay=".2s"
              >
                <span>01.</span>
                <h3>Choose a product</h3>
                <p className="para">
                  This is the easiest part, just click on a product that is live and you'll be taken to the specific product information page. Here you can see detailed information about the product and the current price. You can also see the current leader and their bids.
                </p>
                
              </div>
            </div>

            <div className="col-xl-6 col-lg-6">
              <div
                className="how-work-content wow fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay=".2s"
              >
                <span>02.</span>
                <h3>Examine Current Price</h3>
                <p className="para">
                  Look at the current price of the product to get an idea of the amount you'd like to bid. Since this is a lowest unique bid auction, it generally means that whatever the current price is you may need to dislodge the current leader and bid something lower or higher.
                </p>
                
                
              </div>

            </div>

            <div className="col-xl-6 col-lg-6">
              <div
                className="how-work-content wow fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay=".2s"
              >
                <span>03.</span>
                <h3>Examine Latest Bids</h3>
                <p className="para">
                  This is one of the most important aspects of the auction. You need to look at the current leader and look at their bids. While the auction is running and for example you see a value like ~3, this means that the exact value is either 3.0 or 3.5. So you now have enough information to dislodge the current leader and bid something lower or higher.
                </p>
                
                
              </div>
            </div>

            <div className="col-xl-6 col-lg-6">
              <div
                className="how-work-content wow fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay=".2s"
              >
                <span>04.</span>
                <h3>Dislodge The Leader!</h3>
                <p className="para">
                  Now that you know what the current leader has bid, you can dislodge them by making a bid for example you think that it could be 3.0 you make that bid then wait, if you are not declared the new leader then it means that the value might be 3.5 for example, so you make that bid too, dislodge them then make your own unique bid.
                </p>
               
              </div>
            </div>

            <div className="col-xl-6 col-lg-6">
              <div
                className="how-work-content wow fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay=".2s"
              >
                <span>05.</span>
                <h3>Maintain Your Lead</h3>
                <p className="para">
                  You should try and use bids ending in .5 to maintain your lead, this is because the current leader will always try and dislodge you by using bids ending in .0 by default, so you should do the same to them. When you lose your lead, you'll usually be notified by SMS to get back in the game. You should probably place backup bids in case you lose your lead.
                </p>
               
              </div>
            </div>

            
            
          </div>

          
          


        </div>
      </div>
    </>
  );
}

export default HowToWInContent;
