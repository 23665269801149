import React from "react";
import { Link } from "react-router-dom";

function DemoContent() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  return (
    <>
      <div className="how-work-section pt-120 pb-120">
        <img
          alt="images"
          src={process.env.PUBLIC_URL + "/images/bg/section-bg.png"}
          class="section-bg-top"
        />
        <div className="container">
          <div className="row g-4 mb-60">
            <div className="col-xl-6 col-lg-6">
              <div
                className="how-work-content wow fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay=".2s"
              >
                <span>01.</span>
                <h3>Register Now, practise till you get good</h3>
                <p className="para">
                  Registering a demo account is just as easy, enter your phone number and Screen Name and you'll be ready to go. You'll get your login information immediately with a test balance of 10,000 KES to start immediately.
                </p>
                
                <a
                  href={`https://staging.shukisha.com/signup`}
                  target="_blank"
                  className="eg-btn btn--primary btn--md"
                >
                  Register Demo Account
                </a>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 d-flex justify-content-lg-end justify-content-center">
              <div
                className="how-work-img wow fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay=".2s"
              >
                <img
                  alt="images"
                  src={process.env.PUBLIC_URL + "/images/bg/how-work1.png"}
                  className="work-img"
                />
              </div>
            </div>
          </div>

          <div className="row g-4 mb-60">
            <div className="col-xl-6 col-lg-6 d-flex justify-content-lg-start justify-content-center order-lg-1 order-2">
              <div
                className="how-work-img wow fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay=".2s"
              >
                <img
                  alt="images"
                  src={process.env.PUBLIC_URL + "/images/bg/how-work2.png"}
                  className="work-img"
                />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 order-lg-2 order-1">
              <div
                className="how-work-content wow fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay=".2s"
              >
                <span>02.</span>
                <h3>Learn How it Works</h3>
                <p className="para">
                  Take part in simulated daily auctions. Learn how to place bids, how to win and how to lose. Learn how to use the Shukisha platform and how to use your account balance to purchase items.
                </p>
                
            
              </div>
            </div>
          </div>

          <div className="col-xl-6 col-lg-6 order-lg-2 order-1">
              <div
                className="how-work-content wow fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay=".2s"
              >
                <span>03.</span>
                <h3>Talk to Us</h3>
                <p className="para">
                  We're always here to help. If you have any questions, queries or concerns, feel free to contact us through the whatsapp widget on the bottom right corner of your screen.
                </p>
                
            
              </div>
            </div>
        

         

        </div>
      </div>
    </>
  );
}

export default DemoContent;
