import React from 'react'
import { useState, useEffect , useRef} from "react";
import SweetAlert from 'react-bootstrap-sweetalert';
import { Link , useHistory} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import io from 'socket.io-client';











import {isUserLoggedIn} from '../../../common/data_methods';
import {fetchDataJSON, fetchPostJSON} from '../../../common/services';
import { SOCKET_HOST } from "../../../env";
import Preloader from "../../layout/Preloader";

import AuctionDetailsInfo from './AuctionDetailsInfo'

import AuctionDetailsTab from './AuctionDetailsTab';












  
 
const socket = io(SOCKET_HOST);

 
function AuctionDetailsWrap(props) {
 
  const history = useHistory();

  const [loading, setLoading] = useState(true);

  const [auction, setAuction] = useState({});

  const [latestLeader, setLatestLeader] = useState({});

  const [latestOverlallLeader, setLatestOverallLeader] = useState({});

  const [balance, setBalance] = useState(0);

  const [currentPrice, setCurrentPrice] = useState(0);

  const [bidProcessing, setBidProcessing] = useState(false);

  const [closedError, setClosedError] = useState(false);

  const [insufficientError, setInsufficientError] = useState(false);

  const [latestBids, setLatestBids] = useState([]);

  const [latestRounds, setLatestRounds] = useState([]);

  const [latestLeaderboard, setLatestLeaderboard] = useState([]);

  const [topupPrompt, setTopupPrompt] = useState(false);

  const [topupSubmitSuccess, setTopupSubmitSuccess] = useState(false);

  const [isConnected, setIsConnected] = useState(socket.connected);
  const [lastPong, setLastPong] = useState(null);
  const [socketId, setsocketId] = useState(null);
  const [reconnectPending, setReconnectPending] = useState(false);
  const [firstConnectionComplete, setFirstConnectionComplete] = useState(false);

  let interval = useRef();

  if(!isUserLoggedIn()){
    history.push('/login');
  } 

  const startTimer = () => {

    interval = setInterval(() => {

      if(isUserLoggedIn()){
        
        doLoadCurrentPrice();

        doFetchLatestBids();
      }
      

    }, 20000);
  }

  const sendPing = () => {
    socket.emit('customMsg', { evt: "init" });
  }

  const initTopup = () => {
    setInsufficientError(false);
    setTopupPrompt(true);
  }
  
  const doLoadBalance = async (e)=>{
    const res = await fetchDataJSON('/users/check_balance', true);
    if(res.Ok){
      setBalance(res.data.balance);
    }
  }

  const doLoadCurrentPrice = async (e)=>{
    const res = await fetchDataJSON('/auctions/'+ props.slug + '/current_price', true);
    if(res.Ok){
      setCurrentPrice(res.data.current_price);
    }
  }

  const doFetchLatestBids = async (e)=>{
    const res = await fetchDataJSON('/auctions/'+ props.slug + '/latest_bids', true);
    if(res.Ok){
      setLatestBids(res.data.latest_bids);

      setLatestLeader(res.data.winner_info);

      setLatestLeaderboard(res.data.leaderboard);

      setLatestOverallLeader(res.data.general_leader);

      setLatestRounds(res.data.rounds);
    }
  }

  const doLoadData = async (e)=>{
    setLoading(true);
    const res = await fetchDataJSON('/auctions/' + props.slug + '/info', true);

    const resBalance = await fetchDataJSON('/users/check_balance', true);

    const resLatestBids = await fetchDataJSON('/auctions/' + props.slug + '/latest_bids', true);

    if(res.Ok){
      setAuction(res.data.auction);

      setCurrentPrice(res.data.auction.current_price);
    }

    if(resBalance.Ok){
      setBalance(resBalance.data.balance);
    }

    if(resLatestBids.Ok){
      setLatestBids(resLatestBids.data.latest_bids);

      setLatestLeader(resLatestBids.data.winner_info);

      setLatestLeaderboard(resLatestBids.data.leaderboard);

      setLatestOverallLeader(resLatestBids.data.general_leader);

      setLatestRounds(resLatestBids.data.rounds);
    }
    
    setLoading(false);

    if(!res.data.auction.completed) {
      startTimer();
    }

    sendPing();
  }

  const doRequestPayment = async (e)=>{

    const keys = Object.keys(e);

    let strVal = '';

    for(let i=0; i<keys.length; i++){
      strVal += e[keys[i]];
    }

    const stringVal = parseInt(strVal);

    setBidProcessing(true);

    setTopupPrompt(false);

    const res = await fetchPostJSON('/payments/mpesa/make_payment', {amount: stringVal}, true);

    if(res.Ok){
      setTopupSubmitSuccess(true);
    }

    setBidProcessing(false);
  }

  const showTopupPrompt =  (e)=> {
    if(topupPrompt === true || topupPrompt === 1){
      return(
        <SweetAlert
          title="Top up your account"
          showCancel
          input
          required
          inputType="number"
          validationMsg="You must enter an amount"
          onConfirm={(e) => doRequestPayment(e)}
          onCancel={() => setTopupPrompt(false)}
        >

          Enter an amount
        </SweetAlert>
      )
    }
  }

  const showClosedError =  (e)=> {
    if(closedError === true || closedError === 1){
      return(
        <SweetAlert
          danger
          title="Bid Failed"
          onConfirm={() => setClosedError(false)}
        >
          This auction is closed
        </SweetAlert>
      )
    }
  }

  const showTopSubmitSuccess =  (e)=> {
    if(topupSubmitSuccess === true || topupSubmitSuccess === 1){
      return(
        <SweetAlert
          success
          title="Top up request submitted"
          onConfirm={() => setTopupSubmitSuccess(false)}
        >
          You will receive a prompt to complete the transaction on your phone
        </SweetAlert>
      )
    }
  }

  const showInsufficientBalanceError =  (e)=> {
    if(insufficientError === true || insufficientError === 1){
      return(
        <SweetAlert
          danger
          showCancel
          title="Bid Failed"
          onConfirm={() => initTopup()}
          onCancel={() => setInsufficientError(false)}
          confirmBtnText="Yes"
          cancelBtnText="Later"
        >
          Your balance is insufficient, would you like to top up now?
        </SweetAlert>
      )
    }
  }

  const showSocketConnectingIcon =  (e)=> {
    if(!isConnected){
      return(
        <div className="socket-connecting-icon">
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    }
  }

  const doSubmitBid = async (e)=>{
    e.preventDefault();

    setBidProcessing(true);
 
    if(new Date(auction.end_time_ts * 1000) < new Date()){
      setBidProcessing(false);
      setClosedError(true);
      return;
    }

    // if(e.target.bid_amount.value > balance){
    if(20 > balance){
      setInsufficientError(true);
      setBidProcessing(false);
      return;
    }

    const res = await fetchPostJSON('/auctions/' + props.slug + '/bid',{amount: e.target.bid_amount.value}, true);

    if(res.Ok){

      toast.success("Bid submitted successfully", {
        position: toast.POSITION.TOP_CENTER
      });

      doLoadBalance();

    }
    else{
      toast.error("We were unable to place your bid", {
        position: toast.POSITION.TOP_CENTER
      });
    }

    setBidProcessing(false);
  }

  const onMessage = async (e)=>{
    console.log('onMessage');

    if(e.message.type === 'payment_received') {
      doLoadBalance();
      toast.success(e.message.body, {
        position: toast.POSITION.TOP_CENTER
      });
    }
    else {

      if(e.message.title === 'Leader!'){

        toast.success(e.message.body, {
          position: toast.POSITION.TOP_CENTER
        });

        doLoadCurrentPrice();
      }

      else if(e.message.title === 'Leadership Lost!'){

        toast.warn(e.message.body, {
          position: toast.POSITION.TOP_CENTER
        });

        doLoadCurrentPrice();
      }
    }
    
  }

  const triggerTopupChild = async (e)=> {
    initTopup();
  }

  const onSetup = async (e)=>{
    setsocketId(e.id);
    setIsConnected(true);

    if(isUserLoggedIn()){
      await fetchPostJSON('/users/save_sid', {sid: e.id}, true);
    }
    console.log(e);
  }

  const sendCheckPing = () => {
    socket.emit('ping', {type: 'check_ping'});
  }

  useEffect(() => {

    socket.on('connect', () => {

      setIsConnected(true);

      setFirstConnectionComplete(true);

      sendPing();
    });

    socket.on('disconnect', () => {
      setIsConnected(false);
      setReconnectPending(true);
    });

    socket.on('pong', () => {
      setLastPong(new Date().toISOString());
    });

    socket.on('customNotif', (data) => {
      onMessage(data);
    });

    socket.on('broadcastMsg', (data) => {
      doFetchLatestBids();
      doLoadCurrentPrice();
    });

    socket.on('customEmit', (data) => {
      onSetup(data);
    });

    doLoadData();

   

    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('pong');
      socket.off('customNotif');
      socket.off('customEmit');
      socket.off('customBroadCast');
      clearInterval(interval.current);
    };

    

  }, []);


  
 
  
  return (
    <>
      {loading ? (
        <Preloader styles="preloader" />
      ) : (
        <>
          <div className="auction-details-section pt-120 pb-120">
      <img alt="images" src={process.env.PUBLIC_URL + '/images/bg/section-bg.png'} className="img-fluid section-bg-top" />
        <img alt="images" src={process.env.PUBLIC_URL + '/images/bg/section-bg.png'} className="img-fluid section-bg-bottom" />

        <div className="container">
          
          <AuctionDetailsInfo 
          gallery={auction.gallery}
          end_time={auction.end_time} 
          end_time_ts={auction.end_time_ts}
          product_name={auction.product_name} 
          start_time={auction.start_time}
          current_price={currentPrice}
          product_description_long={auction.product_description_long}
          product_description_short={auction.product_description_short}
          original_price={auction.original_price}
          balance={balance}
          submitBid={doSubmitBid}
          bidProcessing={bidProcessing}
          triggerTopupPrompt={triggerTopupChild}
          completed={auction.completed}
          latest_leader={latestLeader}
          latest_overall_leader={latestOverlallLeader}
        /> 

      {showSocketConnectingIcon()}
 
        <AuctionDetailsTab
          product_description_long={auction.product_description_long}
          product_description_short={auction.product_description_short}
          bids={latestBids}
          leaderboard={latestLeaderboard}
          rounds={latestRounds}
        />

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        </div>
        {showClosedError()}
        {showInsufficientBalanceError()}
        {showTopupPrompt()}
        {showTopSubmitSuccess()}
      </div> 
        </>
      )}
    </>
  );



}

export default AuctionDetailsWrap