import React, { useState } from 'react';
import posthog from 'posthog-js';
import { Link , useHistory} from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';


import Preloader from "../../layout/Preloader";

import {fetchPostJSON, userLoginJSON} from '../../../common/services';

import {saveCurrentToken, saveUserInfo} from '../../../common/data_methods'


function LoginWrap() {
  const [openEye, setOpenEye]= useState(1);

  const [phone, setPhone]= useState();

  const [password, setPassword]= useState();

  const [success, setSucces]= useState(false);

  const [resetSubmitSuccess, setResetSubmitSuccess]= useState(false);

  const [error, setError]= useState(false);

  const [isBusy, setIsBusy]= useState(false);

  const [resetPrompt, setResetPrompt] = useState(false);

  const [result, setResult]= useState();

  const history = useHistory()

  const handleEyeIcon = ()=>{
    if(openEye === false || openEye ===0){
      setOpenEye(1)
    }else{
      setOpenEye(false)
    }
  }

  const doHandleSubmit = async (e)=>{
    e.preventDefault();

    const item = {
      phone: phone.target.value, 
      password: password.target.value
    }

    setIsBusy(true);

    const res = await userLoginJSON(item);

    setIsBusy(false);

    if(res.Ok){
      saveCurrentToken(res.data.token);

      const user = {
        id: res.data.user_id,
        username: res.data.user_name,
        phone: res.data.phone,
        account_balance: res.data.account_balance,
      }

      saveUserInfo(user);

      setSucces(true);
      setError(false);
      posthog.identify(
        res.data.phone, // distinct_id, required
        { phone_number: res.data.phone}, // $set, optional
        { username: res.data.user_name } // $set_once, optional
      );

      history.push('/'); 
    }else{
      setResult(res.status.detail);
      setSucces(false);
      setError(true);
    }
  }

  const doRequestReset = async (e)=>{

    const keys = Object.keys(e);

    let strVal = '';

    for(let i=0; i<keys.length; i++){
      strVal += e[keys[i]];
    }

    const stringVal = strVal;

    setIsBusy(true);

    setResetPrompt(false);

    const res = await fetchPostJSON('/users/request_reset', {phone: stringVal}, true);

    console.log(res);

    setIsBusy(false);

    setResetSubmitSuccess(true);
  }

  const showPromptSuccess =  (e)=> {
    if(resetSubmitSuccess === true || resetSubmitSuccess === 1){
      return(
        <SweetAlert
          success
          title="Reset request submitted"
          onConfirm={() => setResetSubmitSuccess(false)}
        >
          If you have an account with us, you will receive a password reset link via SMS.
        </SweetAlert>
      )
    }
  }

  const showResetPrompt =  (e)=> {
    if(resetPrompt === true || resetPrompt === 1){
      return(
        <SweetAlert
          title="Reset Password"
          showCancel
          input
          required
          inputType="text"
          validationMsg="You must enter a valid phone number"
          onConfirm={(e) => doRequestReset(e)}
          onCancel={() => setResetPrompt(false)}
        >

          Enter your phone number
        </SweetAlert>
      )
    }
  }

  const showError =  (e)=> {
    if(error === true || error === 1){
      return(
        <SweetAlert
          danger
          title="Login Failed"
          onConfirm={() => setError(false)}
        >
          {result}
        </SweetAlert>
      )
    }
  }

  const showLoader =  (e)=> {
    if(isBusy === false || isBusy ===0){

    }else{
      return(
        <Preloader styles="preloader" />
      )
    }
   
  }


  return (
    <>
      <div className="login-section pt-120 pb-120">
        
        <div className="container">
          <div className="row d-flex justify-content-center g-4">
            <div className="col-xl-6 col-lg-8 col-md-10">
              <div className="form-wrapper wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".2s">
                <div className="form-title">
                  <h3>Log In</h3>
                  <p>New Member? <Link to={`${process.env.PUBLIC_URL}/signup`} onClick={()=>window.scrollTo({top:0,behavior:"smooth"})} >signup here</Link></p>
                </div>
                <form className="w-100" onSubmit={doHandleSubmit}>
                  <div className="row">
                    <div className="col-12"> 
                      <div className="form-inner">
                        <label>Enter Your Phone *</label>
                        <input type="number" placeholder="Enter Your Phone eg 0712345677" className='no-incr' required={true} data-arg="phone" onChange={setPhone}/>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-inner">
                        <label>Password *</label>
                        <input type={openEye === 1 ? 'password' : 'text'} name="password" id="password" placeholder="Password" required={true} data-arg="password" onChange={setPassword}/>
                        <i className={openEye ===1 ?"bi bi-eye-slash": "bi bi-eye-slash bi-eye"} id="togglePassword" onClick={handleEyeIcon} />
                      </div>

                      
                      {showLoader()}
                      {showError()}
                      {showResetPrompt()}
                      {showPromptSuccess()}
                    </div>
                    <div className="col-12">
                      <div className="form-agreement form-inner d-flex justify-content-between flex-wrap">
                        <Link to={"#"} onClick={(e)=>setResetPrompt(true)}>Forgot Password?</Link>
                      </div>
                    </div>
                  </div>
                  <button className="account-btn" type="submit">Log in</button>
                </form>

              </div>
            </div>
          </div>
        </div>
      </div>   
    </>
  )
}

export default LoginWrap