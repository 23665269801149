import React from 'react'










import Counter from '../../common/Counter'










 
function AuctionDetailsInfo(props) {
 
  const showloader = () => {
    if(props.bidProcessing){
      return (
        <div className='row d-flex justify-content-center'>
          <div class="loaderx"></div>
        </div>
        
      );
    }
    else{
      return (

        <div className='bid-containerc'>
        <form onSubmit={props.submitBid}>
                <div className="row">
                  
                <div className="col-8 col-lg-6">
                <input  type="number" placeholder="KES 0.0" step="0.5" min={1.0} name="bid_amount" required/>
                </div>

                  <div className="col-4 col-lg-6">
                    <button className="eg-btn btn--primary btn--sm btn-topup-small" type="submit">Place Bid</button>
                  </div>
                  
                  
                </div>
        </form>
        </div>
      );
    }
    
  }


  return (
    <>
     <div className="row g-4 mb-50">
        <div className="col-xl-6 col-lg-7 d-flex flex-row align-items-start justify-content-lg-start justify-content-center flex-md-nowrap flex-wrap gap-4">
          <ul className="nav small-image-list d-flex flex-md-column flex-row justify-content-center gap-4  wow fadeInDown" data-wow-duration="1.5s" data-wow-delay=".4s">
            <li className="nav-item">
              <div id="details-img1" data-bs-toggle="pill" data-bs-target="#gallery-img1" aria-controls="gallery-img1">
                <img alt="images" src={props.gallery[0]} className="img-fluid" />
              </div>
            </li>
            <li className="nav-item">
              <div id="details-img2" data-bs-toggle="pill" data-bs-target="#gallery-img2" aria-controls="gallery-img2">
                <img alt="images" src={props.gallery[1]} className="img-fluid" />
              </div>
            </li>
            <li className="nav-item">
              <div id="details-img3" data-bs-toggle="pill" data-bs-target="#gallery-img3" aria-controls="gallery-img3">
                <img alt="images" src={props.gallery[2]} className="img-fluid" />
              </div>
            </li>
          </ul>
          <div className="tab-content mb-4 d-flex justify-content-lg-start justify-content-center  wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".4s">
            <div className="tab-pane big-image fade show active" id="gallery-img1">
              <div className="auction-gallery-timer d-flex align-items-center justify-content-center flex-wrap">
                <h3 id="countdown-timer-1"><Counter end_time={props.end_time} end_time_ts={props.end_time_ts}/></h3>
              </div>
              <img alt="images" src={props.gallery[0]} className="img-fluid" />
            </div>
            <div className="tab-pane big-image fade" id="gallery-img2">
              <div className="auction-gallery-timer d-flex align-items-center justify-content-center">
                <h3 id="countdown-timer-2"><Counter end_time={props.end_time} end_time_ts={props.end_time_ts}/></h3>
              </div>
              <img alt="images" src={props.gallery[1]} className="img-fluid" />
            </div>
            <div className="tab-pane big-image fade" id="gallery-img3">
              <div className="auction-gallery-timer d-flex align-items-center justify-content-center">
                <h3 id="countdown-timer-3"><Counter end_time={props.end_time} end_time_ts={props.end_time_ts}/></h3>
              </div> 
              <img alt="images" src={props.gallery[2]} className="img-fluid" />
            </div>
          </div>    
        </div> 
        <div className="col-xl-6 col-lg-5">
          <div className="product-details-right  wow fadeInDown" data-wow-duration="1.5s" data-wow-delay=".2s">
            <h3>{props.product_name} {props.completed?'(Ended)': ''}</h3>
            <p className="para">{props.product_description_short}</p>
            <h4>Market Price: <span>KES {props.original_price}</span></h4>
            <br></br>

            <h4>Overall Leader: {props.latest_overall_leader?props.latest_overall_leader.username: 'Not available yet'}</h4>

            <br></br>
            
            <h4>Round Leader: {props.latest_leader?props.latest_leader.username: 'Not available yet'}</h4>
            
          
            <br/>
            <h4>{props.completed?'Final Price: ': 'Current Price: ~'} <span> KES {props.latest_leader?props.latest_leader.bid_value: props.current_price} </span></h4>
            
            <span>* Exact current price is hidden while auction is ongoing, use it to your advantage.</span>
            
            <div className="bid-form">
              <div className="form-title">
                <div className='row'>
                  <div className='col-6 col-xl-8'>
                    <h5>Bid Now</h5>
                  </div>

                  <div className='col-6 col-xl-4'>
                  <button onClick={(e)=>{props.triggerTopupPrompt()}} className="eg-btn btn--secondary btn--sm btn-topup-small" type="submit">Top Up Now</button>
                  </div>
                   
                </div><br/>
                
                <h6 className='current-balance-auction'> Your Current Balance: KES {props.balance} </h6> <br/>
                <h6>Bid Amount : <b>Each bid @ KES 50.00</b></h6> <br></br>

                {showloader()}
                
              </div>
              
            </div>
          </div>
        </div>
      </div>   
    </>
  )
}

export default AuctionDetailsInfo