import React, { useState } from 'react';
import posthog from 'posthog-js';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Link , useHistory} from 'react-router-dom';
import YouTube from 'react-youtube';


















import {saveCurrentToken, saveUserInfo, currentAffiliateId} from '../../../common/data_methods'

import {userSignupJSON} from '../../../common/services';

import Preloader from "../../layout/Preloader";


















function SignUpWrap() {
  const [openEye, setOpenEye]= useState();

  const [phone, setPhone]= useState();

  const [screenName, setScreenName]= useState();

  const [success, setSucces]= useState(false);

  const [error, setError]= useState(false);

  const [acceptedTerms, setAcceptedTerms]= useState(false);

  const [isBusy, setIsBusy]= useState(false);

  const [result, setResult]= useState();

  const history = useHistory()

  const randomUser = `funnyLady${Math.floor(Math.random() * 10000)}`;

  const currentAffiliate = currentAffiliateId();

  const opts = {
    height: '390',
    width: '99%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    }
  };

  // alert(currentAffiliate);

  const handleEyeIcon = ()=>{
    if(openEye === false || openEye ===0){
      setOpenEye(1)
    }else{
      setOpenEye(false)
    }
  }

  const handleAccept = (e)=>{

    if(e.target.checked){
      setAcceptedTerms(true)
    }else{
      setAcceptedTerms(false)
    }
  }

  const doHandleSubmit = async (e)=>{
    e.preventDefault();

   /* if (acceptedTerms === false) {
      setError(true);
      setResult("Please accept terms and conditions");
      return;
    }*/

    const item = {
      phone: phone.target.value, 
      username: screenName.target.value,
      affiliate: currentAffiliate
    }

    setIsBusy(true);

    const res = await userSignupJSON(item);

    setIsBusy(false);

    if(res.Ok){
      saveCurrentToken(res.data.token);

      const user = {
        id: res.data.user_id,
        username: res.data.user_name,
        phone: res.data.phone,
        account_balance: res.data.account_balance,
      }

      saveUserInfo(user);

      setSucces(true);
      setError(false);

      posthog.identify(
        res.data.phone, // distinct_id, required
        { phone_number: res.data.phone}, // $set, optional
        { username: res.data.user_name } // $set_once, optional
      );
      
      history.push('/welcome');
    }else{
      setResult(res.status.detail);
      setSucces(false);
      setError(true);
    }
  }

  const showError =  (e)=> {
    if(error === true || error === 1){
      return(
        <SweetAlert
          danger
          title="Signup Failed"
          onConfirm={() => setError(false)}
        >
          {result}
        </SweetAlert>
      )
    }
  }

  const showVideo =  (e)=> {

    return(
      <YouTube videoId="f-jwRCj3ET4" opts={opts} onReady={_onReady} />
    )
  }

  const _onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }

  const showLoader =  (e)=> {
    if(isBusy === false || isBusy ===0){

    }else{
      return(
        <Preloader styles="preloader" />
      )
    }
   
  }
  return (
    <>
    
     <div className="signup-section pt-120 pb-120">
        <img alt="images" src={process.env.PUBLIC_URL + '/images/bg/section-bg.png'} className="section-bg-top" />
        <img alt="images" src={process.env.PUBLIC_URL + '/images/bg/section-bg.png'} className="section-bg-bottom" />

        
        <div className="container">
        
          <div className="row d-flex justify-content-center">
          
            <div className="col-xl-6 col-lg-8 col-md-10">
            {showVideo()}
              <div className="form-wrapper wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".2s">
                <div className="form-title">
                  <h3>Sign Up Onto Kenya's Best Auction Site</h3>
                  <p>Do you already have an account? <Link to={`${process.env.PUBLIC_URL}/login`} onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}>Log in here</Link></p>
                </div>
                <form className="w-100" onSubmit={doHandleSubmit}>
                  <div className="row">
                   
                    <div className="col-md-12">
                      <div className="form-inner">
                        <label>Enter Your Phone *</label>
                        <input type="number" placeholder={"Enter Your M-PESA Phone eg. 0722456789"} required={true} data-arg="phone" onChange={setPhone}/>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-inner">
                        <label>Screen Name *</label>
                        <input type="text" placeholder={"Enter a unique screen name eg. "  + randomUser} required={true} data-arg="username" onChange={setScreenName}/>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-agreement form-inner d-flex justify-content-between flex-wrap">
                        <div className="form-group">
                          <input type="checkbox" id="html" defaultChecked={acceptedTerms} onChange={(e) => handleAccept(e)}  required/>
                          <label htmlFor="html">I agree to the Terms &amp; Policy</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  {showLoader()}
                  {showError()}
                  {acceptedTerms === false || acceptedTerms === 0 ? <button type="submit" className="account-btn disabled-btn-reg" disabled>Sign Up</button> : <button className="account-btn">Create Account</button>}
                  {acceptedTerms}
                </form>
                
                <div className="form-poicy-area">
                  <p>By clicking the "signup" button, you create a Shukisha account, and you agree to Shukisha's <Link to={"#/terms"}>Terms &amp; Conditions</Link> &amp; <Link to={"#/privacy"}>Privacy Policy.</Link></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>   
    </>
  )
}

export default SignUpWrap