import React from 'react'
import Breadcrumb from '../../common/Breadcrumb'
import { Link , useHistory} from 'react-router-dom';
import ResetWrap from './ResetWrap'
import {isUserLoggedIn} from '../../../common/data_methods'

function Reset(props) {

  const history = useHistory()

  if(isUserLoggedIn()){
    history.push('/dashboard');
  } 

  return (
    <>  
     <ResetWrap token={props['match'].params.token}/> 
    </>
  )
}

export default Reset