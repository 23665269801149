import React from "react";
import { useState, useEffect } from "react";
import { Link , useHistory} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WhatsAppWidget from "react-whatsapp-chat-widget";















import {fetchDataJSON, fetchPostJSON} from '../../../common/services';
import Preloader from "../../../components/layout/Preloader";

import ContentOfAffiliate from "./ContentOfAffiliate";

import ContentOfDashboardTab from "./ContentOfDashboardTab";
import ContentOfOrder from "./ContentOfOrder";
import ContentOfProfile from "./ContentOfProfile";
import DeshbordMenu from "./DashboardMenu";














 
function DashbordWrap() {

  const [userInfo, setUserInfo] = useState({});

  const [affiliateInfo, setAffiliateInfo] = useState({});

  const [affiliatePeriod, setAffiliatePeriod] = useState('');

  const [affiliateCommisions, setAffiliateCommisions] = useState([]);

  const [orderStats, setOrderStats] = useState({});

  const [loading, setLoading] = useState(true);

  const [currentPassword, setCurrentPassword]= useState('');

  const doLoadData = async (e)=>{
    
    setLoading(true);

    const userInfoRes = await fetchDataJSON('/users/user_profile', true);

    const orderStatsRes = await fetchDataJSON('/users/order_stats', true);

    const affiliateInfoRes = await fetchDataJSON('/affiliates/info', true);

    if(userInfoRes.Ok){
      setUserInfo(userInfoRes.data.user);
    }

    if(orderStatsRes.Ok){
      setOrderStats(orderStatsRes.data.stats);
    }

    if(affiliateInfoRes.Ok){
      setAffiliateInfo(affiliateInfoRes.data.affiliate_info);

      const commisionsInfoRes = await fetchDataJSON('/affiliates/commisions', true);

      if(commisionsInfoRes.Ok){
        setAffiliateCommisions(commisionsInfoRes.data.transactions);
        setAffiliatePeriod(commisionsInfoRes.data.period);
      }
    }

    setLoading(false);
  }

  const doHandleSubmit = async (e)=>{
    e.preventDefault();

    const item = {
      email: e.target.email.value, 
      current_password: e.target.password.value,
      first_name: e.target.first_name.value,
      last_name: e.target.last_name.value,
      address: e.target.address.value,
    }

    setLoading(true);

    const res = await fetchPostJSON('/users/update_profile', item, true);

    setLoading(false);

    if(res.Ok){
      toast.success("Profile updated successfully", {
        position: toast.POSITION.TOP_CENTER
      });

      doLoadData();
    }
    else{
      toast.error("check your details and try again", {
        position: toast.POSITION.TOP_CENTER
      });
    }
  }

  const doHandleJoinAffiliate = async (e)=>{

    const item = {
      value: 1
    }

    setLoading(true);

    const res = await fetchPostJSON('/affiliates/join', item, true);

    

    // window.location.href = "/dashboard";

    if(res.Ok){
      toast.success("You have Joined The Affiliate Program!", {
        position: toast.POSITION.TOP_CENTER
      });

      doLoadData();
    }
    else{
      toast.error("We were unable to sign you up for the affiliate program, contact support", {
        position: toast.POSITION.TOP_CENTER
      });
    }

    setLoading(false);
  }

  useEffect(() => {
    
    doLoadData();

  }, []);
 
  return (
    <>
     {loading ? (
        <Preloader styles="preloader" />
      ) : (
        <>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
          <div className="dashboard-section pt-120 pb-120">
        <img
          alt="images"
          src={process.env.PUBLIC_URL + "/images/bg/section-bg.png"}
          className="img-fluid section-bg-top"
        />
        <img
          alt="images"
          src={process.env.PUBLIC_URL + "/images/bg/section-bg.png"}
          className="img-fluid section-bg-bottom"
        />


        <div className="container">
          <div className="row g-4">
            <DeshbordMenu />
            <div className="col-lg-9">
              <div className="tab-content" id="v-pills-tabContent">
                <ContentOfDashboardTab order_stats={orderStats} user_profile={userInfo}/>
                <ContentOfProfile user_profile={userInfo} handleSubmit={doHandleSubmit} currentPassword={currentPassword}/>
                <ContentOfAffiliate affiliate_info={affiliateInfo} affiliate_commisions={affiliateCommisions} affiliate_period={affiliatePeriod} handleJoin={doHandleJoinAffiliate}/>
              </div>
            </div>
          </div>
        </div>

        <WhatsAppWidget
			phoneNo="254205002033"
			position="right"
			widgetWidth="300px"
			widgetWidthMobile="260px"
			autoOpen={false}
			autoOpenTimer={5000}
			messageBox={true}
			messageBoxTxt="Hi"
			iconSize="40"
			iconColor="white"
			iconBgColor="green"
			headerIcon="https://www.shukisha.com/images/bg/shukisha-logo-v2.svg"
			headerIconColor="pink"
			headerTxtColor="black"
			headerBgColor="#32c36c"
			headerTitle="Shukisha Support"
			headerCaption="Online"
			bodyBgColor="#bbb"
			chatPersonName="Support"
			chatMessage={<>Hi there 👋 <br /><br /> Welcome to Shukisha. How can we be of assistance?</>}
			footerBgColor="white"
			btnBgColor="#d63384"
			btnTxtColor="black"
			btnTxt="Start Chat"
		/>
      </div>
        </>
      )}

      
    </>
  );
}

export default DashbordWrap;
