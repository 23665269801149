import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import WOW from "wowjs";
function Footer(props) {
  
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();

  }, []);
  const scrollTop=()=>window.scrollTo({top:0,behavior:"smooth"})
  return (
    <>
      <footer>
        <div className="footer-top">
          <div className="container">
            <div className="row gy-5">
              <div className="col-lg-4 col-md-6">
                <div className="footer-item">
                  <Link tio={`${process.env.PUBLIC_URL}/`} onclick={scrollTop}><img alt="images" src={process.env.PUBLIC_URL + "/images/bg/shukisha-logo-v2.svg"} height={260} /></Link>
                  
                </div>
              </div>
              <div className="col-lg-4 col-md-6 d-flex justify-content-lg-center">
                <div className="footer-item">
                  <h5>Navigation</h5>
                  <ul className="footer-list">
                    <li><Link onClick={scrollTop} to={`${process.env.PUBLIC_URL}/how-works`}>How It Works</Link></li>
                    <li><Link onClick={scrollTop} to={`${process.env.PUBLIC_URL}/dashboard`}>My Account</Link></li>
                    <li><Link onClick={scrollTop} to={`${process.env.PUBLIC_URL}/about`}>About Us</Link></li>
                   
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 d-flex justify-content-lg-center">
                <div className="footer-item">
                  <h5>Help &amp; FAQs</h5>
                  <ul className="footer-list">
                    <li><Link to={`${process.env.PUBLIC_URL}/faq`} onClick={scrollTop}>Customer FAQs</Link></li>
                    <li><Link to={`${process.env.PUBLIC_URL}/terms`} onClick={scrollTop}>Terms and Conditions</Link></li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row d-flex align-items-center g-4">
              <div className="col-lg-6 d-flex justify-content-lg-start justify-content-center">
                <p>Copyright &copy; 2023 <Link to={"#"}></Link> Shukisha</p>


                <p> License No.4381</p>

                
              </div>
              
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
